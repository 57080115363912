import { useContext, useEffect, useState } from "react";
import Proceso from "./proceso";
import { useParams } from "react-router-dom/cjs/react-router-dom.min";
import apiAxios from "../../../../../config/ApiAxios";
import { AuthContext, Spinner, clienteAxios } from "../../../../shared";
import { format } from "date-fns";
import { ApiRestPost } from "../../../../../Helpers/ApiRestPost";
import AlertSuccess from "../../../../CustomAlerts/AlertSuccess";
import AlertWarning from "../../../../CustomAlerts/AlertWarning";

const ProcesoController = () => {
  const { persona_id } = useParams();
  const authContext = useContext(AuthContext);
  const { usuario } = authContext;
  const [loading, setLoading] = useState(null);
  const [persona, setPersona] = useState(null);
  const [personas, setPersonas] = useState([]);
  const [asignados, setAsignados] = useState([]);
  const [estados, setEstados] = useState(null);
  const [formS, setFormS] = useState({
    id: null,
    tarea_id: null,
    fecha_vencimiento: new Date(),
    comentarios: "",
  });
  const [formT, setFormT] = useState({
    id: null,
    index: null,
    usuario_id: usuario.id,

    estado: "pendiente",
    prioridad: "baja",
    resultado: "",
    tipo_id: null,
    tipo: null,
    subTipo: "",
    fecha_vencimiento: format(new Date(), "Y-MM-dd").toString(),
    created_at: format(new Date(), "Y-MM-dd").toString(),
    hora_contacto: "",
    persona_id: null,
    ultimo_seguimiento: [],
    seguimientos: [],

    usuario_asignado: {
      name: usuario.nombre,
      email: "",
    },
    asignado_id: usuario.id,
    asunto: "",
    descripcion: "",
  });
  const [form, setForm] = useState({
    id: null,
    persona_id: null,
    estado: "activo",
    motivo_baja: "",
    usuario_baja_id: "",
    created_at: new Date(),
    updated_at: new Date(),
    tareas: [],
    seguimientos: [],
    actualizar: false,
  });

  const [paramsP, setParamsP] = useState({
    buscar: "",
    page: 1,
    paginator: 30,
  });

  const [paramsA, setParamsA] = useState({
    buscar: "",
    page: 1,
    paginator: 30,
  });

  const tipo = [
    {
      id: 1,
      name: "Intimación | Teléfono",
    },
    {
      id: 2,
      name: "Intimación | Carta",
    },
    {
      id: 3,
      name: "Intimación | Mensaje Whatsapp",
    },

    {
      id: 4,
      name: "Búsqueda | Trabajo",
    },
    {
      id: 5,
      name: "Búsqueda | Bienes",
    },
    {
      id: 6,
      name: "Búsqueda | Mensaje Varios",
    },

    {
      id: 7,
      name: "Análisis | Varios",
    },
    {
      id: 8,
      name: "Análisis | Juicio",
    },
    {
      id: 9,
      name: "Análisis | Desistido",
    },
  ];

  const fetchTareas = async (id) => {
  };

  const onClick = async (e, value) => {
    switch (e.target.name) {
      case "tarea":
        {
          setFormT({
            id: null,
            index: null,
            usuario_id: usuario.id,

            estado: "pendiente",
            prioridad: "baja",
            resultado: "",
            tipo_id: null,
            tipo: null,
            subTipo: "",
            fecha_vencimiento: format(new Date(), "Y-MM-dd").toString(),
            created_at: format(new Date(), "Y-MM-dd").toString(),
            hora_contacto: "",
            persona_id: null,
            ultimo_seguimiento: [],

            usuario_asignado: {
              name: usuario.nombre,
              email: "",
            },
            asignado_id: usuario.id,
            asunto: "",
            descripcion: "",
          });

          setParamsA({
            ...paramsA,
            buscar: usuario.nombre + " | " + usuario.email,
          });
        }
        break;
      case "guardar-proceso":
        {
          let formData = new FormData();
          formData.append("_method", "PUT");
          formData.append("estado", form.estado);
          formData.append("motivo_baja", null);
          formData.append("usuario_baja_id", null);
          const response = await ApiRestPost({
            url: "/procesos/" + form.id,
            setLoading: setLoading,
            form: formData,
          });

          if (response.status == 200 || response.status == 201) {
            return AlertSuccess({
              message: "Proceso actualizado",
            });
          }
        }
        break;
      case "close":
        {
          setParamsA({ ...{ buscar: "", page: 1, paginator: 30 } });
          setFormT({
            ...{
              id: null,
              index: null,
              usuario_id: usuario.id,

              estado: "pendiente",
              prioridad: "baja",
              resultado: "exito",
              tipo_id: null,
              tipo: null,
              subTipo: "",
              fecha_vencimiento: format(new Date(), "Y-MM-dd").toString(),
              created_at: format(new Date(), "Y-MM-dd").toString(),
              hora_contacto: "",
              persona_id: null,

              usuario_asignado: "",
              asignado_id: null,
              asunto: "",
              descripcion: "",
            },
          });
          setFormS({
            ...{
              id: null,
              tarea_id: null,
              comentarios: "",
            },
          });
        }
        break;
      case "ver-seguimiento":
        {
          setFormS({
            ...value,
          });

          return document.getElementById("btn-agregar-seguimiento").click();
        }
        break;
      case "crear-seguimiento":
        {
          if (!formS.tarea_id) {
            return AlertWarning({
              message: "Debe asignar una tarea",
            });
          }

          let formData = new FormData();
          formData.append("tarea_id", formS.tarea_id);
          formData.append("fecha_vencimiento", formS.fecha_vencimiento);
          formData.append("comentarios", formS.comentarios);

          const response = await ApiRestPost({
            url: "/tareas/" + formS.tarea_id + "/seguimientos",
            setLoading: setLoading,
            form: formData,
          });
          let arr = form.seguimientos;
          arr.push({
            ...formS,
            id: response.data.data.id,
            created_at: response.data.data.created_at,
          });
          setForm({
            ...form,
            seguimientos: arr,
          });
          return document.getElementById("btn-close-modal-seguimiento").click();
        }
        break;
      case "eliminar-seguimiento":
        {
          let formData = new FormData();
          formData.append("_method", "DELETE");
          const response = await ApiRestPost({
            url: "/seguimientos/" + value.id,
            setLoading: setLoading,
            form: formData,
          });

          if (response.status == 200 || response.status == 201) {
            let arr = form.seguimientos;
            delete arr[value.index];
            arr = arr.filter((a) => a !== null);
            setForm({
              ...form,
              seguimientos: arr,
            });
            AlertSuccess({
              message: "Seguimiento eliminado",
            });
          }
        }
        break;
      case "modificar-tarea":
        {
          setFormT({
            ...value,
            tipo: value.tipo.tipo + " | " + value.tipo.subtipo,
            tipo_id: value.tipo.id,
          });

          setParamsA({
            ...paramsA,
            buscar:
              value.usuario_asignado.email +
              " | " +
              value.usuario_asignado.name,
          });
        }
        break;
      case "eliminar-tarea":
        {
          let formData = new FormData();
          formData.append("_method", "PUT");
          formData.append("estado", "descartada");
          const response = await ApiRestPost({
            url: "/tareas/" + value.id,
            setLoading: setLoading,
            form: formData,
          });

          if (response.status == 200 || response.status == 201) {
            fetchTareas();
            AlertSuccess({
              message: "Proceso eliminado",
            });
          }
        }
        break;
      case "crear-tarea":
        {
          if (!formT.asignado_id) {
            return AlertWarning({
              message: "Debe asignar la tarea a un usuario antes de guardarla",
            });
          }

          if (!formT.tipo_id) {
            return AlertWarning({
              message: "Debe seleccionar un tipo | subTipo de tarea",
            });
          }

          if (String(form.hora_contacto).trim() == "") {
            return AlertWarning({
              message: "Debe seleccionar una hora de contacto",
            });
          }

          if (formT.asunto.trim() == "" || formT.descripcion.trim() == "") {
            return AlertWarning({
              message: "Los campos de asunto y tarea son obligatorios",
            });
          }

          let formData = new FormData();
          formData.append("fecha_vencimiento", formT.fecha_vencimiento);
          formData.append("hora_contacto", formT.hora_contacto);
          formData.append("asunto", formT.asunto);
          formData.append("descripcion", formT.descripcion);
          //formData.append("resultado", formT.resultado == "exito" ? 1 : 0);
          formData.append("prioridad", formT.prioridad);
          formData.append("tipo_id", formT.tipo_id);
          formData.append("estado", formT.estado);

          formData.append("proceso_id", form.id);
          formData.append("usuario_asignado_id", formT.asignado_id);
          formData.append("notificar_usuario", 1);

          const response = await ApiRestPost({
            url: "/procesos/" + form.id + "/tareas",
            setLoading: setLoading,
            form: formData,
          });

          if (response.status == 200 || response.status == 201) {
            const tareas = await apiAxios.get(
              `/tareas?filter[proceso_id]=${form.id}&sort=-fecha_vencimiento`
            );
            setForm({...form, tareas: tareas.data.data});
            AlertSuccess({
              message: "Tarea creada",
            });
            return document.getElementById("btn-close-modal-agregar").click();
          }
        }
        break;
      case "actualizar-tarea":
        {
          let formData = new FormData();
          let time = formT.hora_contacto.split(":");
          formData.append("_method", "PUT");
          formData.append("fecha_vencimiento", formT.fecha_vencimiento);
          formData.append("hora_contacto", time[0] + ":" + time[1]);
          formData.append("asunto", formT.asunto);
          formData.append("descripcion", formT.descripcion);
          formData.append("resultado", formT.resultado == "exito" ? 1 : 0);
          formData.append("prioridad", formT.prioridad);
          formData.append("tipo_id", formT.tipo_id);
          formData.append("estado", formT.estado);
          formData.append("notificar_usuario", 1);

          const response = await ApiRestPost({
            url: "/tareas/" + formT.id,
            setLoading: setLoading,
            form: formData,
          });

          if (response.status == 200 || response.status == 201) {
            fetchTareas();
            AlertSuccess({
              message: "Tarea actualizado",
            });
          }
          return document.getElementById("btn-close-modal-actualizar").click();
        }
        break;
      case "seguimiento":
        {
          setFormS({
            ...formS,
            tarea_id: value.id,
          });
        }
        break;
      case "asignado":
        {
          //reset
          setParamsA({
            ...paramsP,
            buscar: value.email + " | " + value.name,
          });

          setFormT({
            ...formT,
            usuario_asignado: value,
            asignado_id: value.id,
          });
          setAsignados([]);
        }
        break;
      case "persona":
        {
          //reset
          setParamsP({
            ...paramsP,
            buscar: value.nombre + " " + value.apellido,
          });

          setPersona({ ...value });
          setForm({
            ...form,
            persona_id: value.id,
          });
        }
        break;
    }
  };

  const onChange = (e, value) => {
    switch (e.target.name) {
      case "estado":
        {
          setForm({
            ...form,
            [e.target.name]: e.target.value,
            actualizar: true,
          });
        }
        break;
      case "buscar":
        {
          setParamsP({
            ...paramsP,
            [e.target.name]: e.target.value,
            page: 1,
            paginator: 30,
          });
        }
        break;
    }
  };

  const fetchAsignados = async (params) => {
    const response = await clienteAxios.get("/users", {
      params: { texto: params.buscar, page: 1, paginator: 30 },
    });
    setAsignados(response.data.usuarios);
  };

  //personas
  useEffect(() => {
    const load = async () => {
      const response = await clienteAxios.get("/persona", {
        params: { id: persona_id },
      });
      
      if (response.status == 200 || response.status == 201) {
        setPersona(response.data.persona);
        const persona = await apiAxios.get(
          `/procesos?filter[id]=${response.data.persona.id}`
        );
        const proceso = persona.data.data[0].procesos[0];

        const tareas = await apiAxios.get(
          `/tareas?filter[proceso_id]=${proceso.id}&sort=-fecha_vencimiento`
        );

        setForm({
          ...form,
          id: proceso.id,
          persona_id: response.data.persona.id,
          usuario_asignado: {
            name: usuario.nombre,
            email: "",
          },
          estado: proceso.estado,
          asignado_id: usuario.id,
          created_at: proceso.created_at,
          tareas: tareas.data.data,
        });
        setParamsP({
          ...paramsP,
          buscar:
            response.data.persona.nombre + " " + response.data.persona.apellido,
        });
        setParamsA({
          ...paramsP,
          buscar: usuario.nombre,
        });
      }
    };
    load();
  }, []);

  return (
    <Proceso
      onClick={onClick}
      onChange={onChange}
      estados={estados}
      paramsP={paramsP}
      paramsA={paramsA}
      setParamsA={setParamsA}
      personas={personas}
      persona={persona}
      data={form}
      setData={setForm}
      form={formT}
      setForm={setFormT}
      formS={formS}
      setFormS={setFormS}
      usuario={usuario}
      asignados={asignados}
      tipo={tipo}
      fetchAsignados={fetchAsignados}
    />
  );
};

export default ProcesoController;
