import TablaProcesos from "./tablas/tabla";

const GestionProcesos = ({
  onClick,
  onChange,
  data,
  params,
  sort,
  setSort,
}) => {
  return (
    <div className="">
      <div className="row">
        <div className="col-md-12 mb-5 d-flex justify-content-start align-items-center">
          <h3 className="text-nowrap me-4 fw-bold">GESTION DE PROCESOS</h3>
          <div class="input-group">
            <button
              class="input-group-text bg-transparent border-0"
              name="search"
              onClick={(e) =>
                onChange(e, document.getElementById("input-search").value)
              }
            >
              <i class="bi bi-search"></i>
            </button>
            <input
              type="text"
              class="form-control"
              id="input-search"
              style={{ borderRadius: "20px", maxWidth: "400px" }}
              placeholder="Buscar por persona"
              name="search"
              onKeyUp={(e) => {
                if (e.code === "Enter") {
                  onChange(e, e.target.value);
                }
              }}
            />
          </div>
        </div>

        <div className="col-md-12">
          <div className="w-100 d-flex justify-content-between mb-3">
            <div className="btn btn-light">
              Total de registros {data?.total}
            </div>
            <div>
              {data?.links.map((l, index) => {
                if (index == 0) {
                  return (
                    <label className="btn btn-primary fs-5 me-2">
                      <i class="bi bi-arrow-left"></i>
                      <button
                        className="d-none"
                        name="anterior"
                        onClick={(event) => onClick(event)}
                      ></button>
                    </label>
                  );
                }

                if (index == data?.links.length - 1) {
                  return (
                    <label className="btn btn-primary fs-5">
                      <i class="bi bi-arrow-right"></i>
                      <button
                        className="d-none"
                        name="siguiente"
                        onClick={(event) => onClick(event)}
                      ></button>
                    </label>
                  );
                }

                return (
                  <label
                    className={
                      params.page == l.label
                        ? "btn btn-primary fs-5 me-2"
                        : "btn btn-outline-primary fs-5 me-2"
                    }
                  >
                    {l.label}
                    <button
                      className="d-none"
                      name="link"
                      onClick={(event) => onClick(event, l.label)}
                    ></button>
                  </label>
                );
              })}
            </div>
          </div>

          <TablaProcesos
            onClick={onClick}
            onChange={onChange}
            data={data?.data}
            sort={sort}
            setSort={setSort}
          />

          <div className="w-100 d-flex justify-content-between mb-3">
            <div className="btn btn-light">
              Total de registros {data?.total}
            </div>
            <div>
              {data?.links.map((l, index) => {
                if (index == 0) {
                  return (
                    <label className="btn btn-primary fs-5 me-2">
                      <i class="bi bi-arrow-left"></i>
                      <button
                        className="d-none"
                        name="anterior"
                        onClick={(event) => onClick(event)}
                      ></button>
                    </label>
                  );
                }

                if (index == data?.links.length - 1) {
                  return (
                    <label className="btn btn-primary fs-5">
                      <i class="bi bi-arrow-right"></i>
                      <button
                        className="d-none"
                        name="siguiente"
                        onClick={(event) => onClick(event)}
                      ></button>
                    </label>
                  );
                }

                return (
                  <label
                    className={
                      params.page == l.label
                        ? "btn btn-primary fs-5 me-2"
                        : "btn btn-outline-primary fs-5 me-2"
                    }
                  >
                    {l.label}
                    <button
                      className="d-none"
                      name="link"
                      onClick={(event) => onClick(event, l.label)}
                    ></button>
                  </label>
                );
              })}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default GestionProcesos;
