import { useEffect, useRef, useState } from "react";
import HeaderComponent from "./HeaderComponent";
import clienteAxios from "../../../config/axios";
import { Permissions } from "../../../utils/permissions";
import { useDetectOutsideClick } from "../DropdownMenu/useDetectOutsideClick";
import axios from "axios";

const HeaderController = ({ usuario, pathname, cerrarSesion }) => {
  const [personas, setPersonas] = useState([]);
  const [search, setSearch] = useState({
    buscar: "",
    page: 1,
    paginator: 30,
  });
  const [notificaciones, setNotificaciones] = useState(null);
  const [consultar, setConsultar] = useState(null);
  const dropdownRef = useRef(null);
  const [isActive, setIsActive] = useDetectOutsideClick(dropdownRef, false);
  const path = [
    {
      icon: ' <i class="bi bi-coin me-2 fs-5"></i>',
      title: "Pagos",
      path: "/pagos",
      dropdown: false,
      nav_dropdown: [],
    },
    {
      icon: ' <i class="bi bi-bar-chart-fill me-2 fs-5"></i>',
      title: "Reportes",
      path: "/reportes",
      dropdown: false,
      nav_dropdown: [],
    },
    {
      icon: '<i class="bi bi-credit-card me-2 fs-5"></i>',
      title: "Creditos",
      path: "/creditos",
      dropdown: false,
      nav_dropdown: [],
    },
    {
      icon: '<i class="bi bi-credit-card-fill me-2 fs-5"></i>',
      title: "Gastos",
      path: "/gestion de mora/gastos",
      dropdown: false,
      nav_dropdown: [],
    },
    {
      icon: '<i class="bi bi-calculator me-2 fs-5"></i>',
      title: "Calculadora",
      path: "/prestamos/calculadora",
      dropdown: false,
      nav_dropdown: [],
    },
    /*
    {
      icon: "",
      title: "Gestion de mora",
      path: "/gestion de mora",
      dropdown: true,
      nav_dropdown: [
        {
          icon: "",
          title: "Gastos judiciales",
          path: "/gestion de mora/gastos",
        },
        {
          icon: "",
          title: "Concesiones",
          path: "/gestion de mora/concesiones",
        }
      ]
    },
    */
  ];

  const Quitarnotificacion = async (item) => {
    try {
      await clienteAxios.post("/Notificaciones/update", { id: item.id });
      setIsActive(!isActive);
      window.location = item.link;
    } catch (e) {}
  };

  const onClickNotificacion = (item) => {
    Quitarnotificacion(item);
  };

  let cancelTokenSource;

  const fetch = async (filter) => {
    if (cancelTokenSource) {
      cancelTokenSource.cancel(
        "Petición cancelada debido a una nueva solicitud."
      );
    }

    cancelTokenSource = axios.CancelToken.source();
    let params = { ...filter, texto: filter.buscar };
    const response = await clienteAxios
      .get("/Persona/getAll", {
        params,
        cancelToken: cancelTokenSource.token,
      })
      .then((response) => {
        return response;
      })
      .catch((error) => {
        if (axios.isCancel(error)) {
          return error;
        } else {
          return error;
        }
      });

    if (response.status == 200 || response.status == 201) {
      setPersonas(response.data.personas.personas);
    }
  };

  const onClick = (e) => {
    switch (e.target.name) {
      case "persona":
        {
          setSearch({
            ...search,
            buscar: "",
          });
        }
        break;
    }
  };

  const onChange = (e) => {
    switch (e.target.name) {
      case "buscar":
        {
          if (e.target.value.length > 3) {
            setSearch({
              ...search,
              [e.target.name]: e.target.value,
              page: 1,
              paginator: 30,
            });
            let params = {
              ...search,
              [e.target.name]: e.target.value,
              page: 1,
              paginator: 30,
            };
            fetch(params);
          } else {
            if (e.target.value.length == "") {
              setSearch({
                ...search,
                [e.target.name]: "",
                page: 1,
                paginator: 30,
              });
              let params = {
                ...search,
                [e.target.name]: e.target.value,
                page: 1,
                paginator: 30,
              };
              fetch(params);
            }
          }
        }
        break;
    }
  };

  useEffect(() => {
    const notificar = async () => {
      try {
        if (!notificaciones) {
          const result = await clienteAxios.get("/Notificaciones/getAll", {
            params: { id: usuario.rol },
          });
          setNotificaciones(result.data.observaciones);
        }
      } catch (e) {}
    };
    notificar();
  }, [consultar]);
  return (
    <HeaderComponent
      usuario={usuario}
      pathname={pathname}
      path={path}
      cerrarSesion={cerrarSesion}
      notificaciones={notificaciones}
      onClickNotificacion={onClickNotificacion}
      Quitarnotificacion={Quitarnotificacion}
      personas={personas}
      search={search}
      onChange={onChange}
      onClick={onClick}
    />
  );
};

export default HeaderController;
