import { format } from "date-fns";
import Modal from "../../../../../module/modal/Modal";
import Tarea from "../modals/tarea";
import TablaTareas from "../tablas/tablaTareas";
import Seguimiento from "../modals/seguimiento";
import TablaSeguimiento from "../tablas/tablaSeguimiento";
import { Spinner } from "../../../../shared";

const autocomplete = {
  top: "60px",
  zIndex: "2",
};

const Proceso = ({
  tipo,
  onClick,
  onChange,
  data,
  setData,
  form,
  setForm,
  paramsP,
  personas,
  persona,
  usuario,
  paramsA,
  setParamsA,
  asignados,
  fetchAsignados,
  formS,
  setFormS,
}) => {

  console.log(data);
  

  if (!data.id) {
    return (
      <div>
        <div className="row">
          <div className="col-md-12 mb-5 d-flex justify-content-center align-items-center">
            <h3 className="text-nowrap me-4 fw-bold">
              <Spinner />
            </h3>
          </div>
        </div>
      </div>
    );
  }

  return (
    <div className="">
      <div className="row">
        <div className="col-md-12 mb-5 d-flex justify-content-start align-items-center">
          <h3 className="text-nowrap me-4 fw-bold">Proceso</h3>
        </div>
      </div>

      <div className="row mb-3">
        <div className="col-md-5">
          <div class="mb-3 w-100 d-flex justify-content-between align-items-center">
            <label
              class="form-label fw-bold text-nowrap me-2 fs-5"
              style={{ width: "200px" }}
            >
              ID proceso
            </label>
            <input
              type="text"
              class="form-control fs-4 py-1"
              style={{ borderRadius: "20px" }}
              name="interes_moratorio"
              onChange={(event) => onChange(event)}
              value={data.id}
              disabled={true}
            />
          </div>
        </div>
        <div className="col-md-5">
          <div class="mb-3 w-100 d-flex justify-content-between align-items-center">
            <label
              class="form-label fw-bold text-nowrap me-2 fs-5"
              style={{ width: "200px" }}
            >
              Fecha
            </label>
            <input
              type="date"
              class="form-control fs-5 py-1"
              style={{ borderRadius: "20px" }}
              name="fecha-proceso"
              onChange={(event) => onChange(event)}
              value={format(new Date(data.created_at), "Y-MM-dd").toString()}
            />
          </div>
        </div>

        <div className="col-md-5">
          <div className="mb-3 d-flex justify-content-between align-items-center">
            <label
              class="form-label fw-bold text-nowrap me-2 fs-5 border-0"
              style={{ width: "200px" }}
            >
              Cliente
            </label>
            <div class="input-group border-0">
              <input
                type="text"
                class="form-control fs-5 py-1"
                style={{ borderRadius: "20px" }}
                placeholder="Busca una persona para añadir un gasto."
                aria-describedby="basic-addon2"
                autoComplete="off"
                name="buscar"
                onChange={(event) => onChange(event)}
                value={paramsP.buscar}
              />

              {paramsP.buscar.trim() !== "" ? (
                <ul
                  class="list-group position-absolute w-100"
                  style={autocomplete}
                >
                  {personas.map((persona) => (
                    <li class="list-group-item w-100 p-0">
                      <label className="border-0  btn btn-outline-dark w-100 d-flex justify-content-start">
                        <div className="me-2">
                          {persona.nombre + " " + persona.apellido}
                        </div>
                        <div className="">
                          <span className="fw-bold">DNI: </span>
                          {persona.nro_documento}
                        </div>
                        <button
                          title="Seleccionar persona"
                          className="d-none"
                          name="persona"
                          onClick={(event) => onClick(event, persona)}
                        ></button>
                      </label>
                    </li>
                  ))}
                </ul>
              ) : (
                ""
              )}
            </div>
          </div>
        </div>
        <div className="col-md-5">
          <div class="mb-3 w-100 d-flex justify-content-between align-items-center">
            <label
              class="form-label fw-bold text-nowrap me-2 fs-5"
              style={{ width: "200px" }}
            >
              Estado
            </label>
            <select
              class="form-select fs-5 py-1"
              style={{ borderRadius: "20px" }}
              aria-label="Default select example"
              name="estado"
              onChange={(event) => onChange(event)}
            >
              {["activo", "inactivo", "juicio"].map((e) => {
                if (e == data.estado) {
                  return <option selected>{e}</option>;
                }
                return <option value={e}>{e}</option>;
              })}
            </select>
          </div>
        </div>
      </div>

      <div className="row">
        <div className="col-md-3 col-12 mb-5">
          <label
            className="btn btn-primary py-2 fs-6 d-flex me-3 text-nowrap text-center d-flex justify-content-center"
            style={{
              borderRadius: "20px",
            }}
          >
            <i class="bi bi-plus-lg me-2"></i>
            Agregar Tarea
            <button
              data-bs-toggle="modal"
              data-bs-target="#agregar-tarea"
              className="d-none"
              id="btn-agregar-tarea"
              name="tarea"
              onClick={(event) => onClick(event, null)}
            ></button>
          </label>
        </div>

        <div className="col-md-12 mb-5">
          {data.tareas.length > 0 ? (
            <TablaTareas data={data.tareas} onClick={onClick} />
          ) : null}
        </div>

        <div className="col-md-12 d-flex justify-content-end">
          <label
            className={
              data.actualizar ? "btn btn-primary" : "btn btn-primary disabled"
            }
          >
            Actualizar Proceso
            <button
              className="d-none"
              name="guardar-proceso"
              onClick={(event) => onClick(event)}
            ></button>
          </label>
        </div>
      </div>

      <Modal
        title={"Tarea ID" + form.id}
        id="actualizar-tarea"
        width={"900px"}
        onClose={{
          id: "btn-close-modal-actualizar",
          name: "close",
          function: onClick,
        }}
        onClick={{
          title: "ACTUALIZAR",
          name: "actualizar-tarea",
          function: () => {
            onClick({
              target: { name: "actualizar-tarea" },
            });
          },
        }}
        children={
          <Tarea
            fetchAsignados={fetchAsignados}
            tipo={tipo}
            persona={persona}
            data={data}
            setData={setData}
            form={form}
            setForm={setForm}
            usuario={usuario}
            asignados={asignados}
            paramsA={paramsA}
            setParamsA={setParamsA}
            onClick={onClick}
          />
        }
      />

      <Modal
        title={form.id ? "Tarea ID" + form.id : "Agregar Taréa"}
        id="agregar-tarea"
        width={"900px"}
        onClose={{
          id: "btn-close-modal-agregar",
          name: "close",
          function: onClick,
        }}
        onClick={{
          title: "GUARDAR",
          name: "crear-tarea",
          function: () => {
            onClick({
              target: { name: form.id ? "actualizar-tarea" : "crear-tarea" },
            });
          },
        }}
        children={
          <Tarea
            fetchAsignados={fetchAsignados}
            tipo={tipo}
            persona={persona}
            data={data}
            setData={setData}
            form={form}
            setForm={setForm}
            usuario={usuario}
            asignados={asignados}
            paramsA={paramsA}
            setParamsA={setParamsA}
            onClick={onClick}
          />
        }
      />

      <Modal
        title={formS.id ? "Seguimiento ID" + formS.id : "Agregar Seguimiento"}
        id="agregar-seguimiento"
        width={"900px"}
        onClose={{
          id: "btn-close-modal-seguimiento",
          name: "close",
          function: onClick,
        }}
        onClick={{
          title: formS.id ? "ACTUALIZAR" : "GUARDAR",
          name: "crear-seguimiento",
          function: () => {
            onClick({
              target: {
                name: formS.id ? "actualizar-seguimiento" : "crear-seguimiento",
              },
            });
          },
        }}
        children={
          <Seguimiento
            tareas={data.tareas}
            data={data}
            setData={setData}
            form={formS}
            setForm={setFormS}
            usuario={usuario}
            onClick={onClick}
          />
        }
      />
    </div>
  );
};

export default Proceso;
