import React, { useReducer } from "react";
import authReducer from "../autenticacion/authReducer";
import authContext from "../autenticacion/authContext";
import clienteAxios from "../../config/axios";

import {
  REGISTRO_ERROR,
  USUARIO_AUTENTICADO,
  LOGIN_EXITO,
  LOGIN_ERROR,
  CERRAR_SESION,
  REGISTRO_EXITO,
  OBTENER_USUARIOS,
  ACTUALIZAR_USUARIO,
  SELECCIONAR_USUARIO,
  ELIMINAR_USUARIO,
  CARGANDO,
  ORDENAR_FILTRAR_USUARIOS,
  DESELECCIONAR_USUARIO
} from "../../types";

const AuthState = (props) => {
  const initialState = {
    token: localStorage.getItem("token"),
    autenticado: null,
    usuario: null,
    usuarioSeleccionado: null,
    usuarios: [],
    resultado: [],
    cargando: false,
    mensaje: { msg: null, class: null },
  };

  const [state, dispatch] = useReducer(authReducer, initialState);

  const ordenarFiltrar = (filtros, campoOrden, tipoOrden) => {
    let elementos = state.usuarios;

    if (filtros !== null) {
      elementos = elementos
        .filter((item) =>
          String(item.user_username)
            .toLowerCase()
            .startsWith(`${filtros.user_username !== undefined ? filtros.user_username : ""}`)
        )
        .filter((item) =>
          String(item.name)
            .toLowerCase()
            .startsWith(
              `${filtros.name !== undefined ? filtros.name : ""}`
            )
        )
        .filter((item) =>
          String(item.lastname)
            .toLowerCase()
            .startsWith(
              `${
                filtros.lastname !== undefined ? filtros.lastname : ""
              }`
            )
        )
        .filter((item) =>
          String(item.email)
            .toLowerCase()
            .startsWith(
              `${
                filtros.email !== undefined
                  ? filtros.email
                  : ""
              }`
            )
        )
        .filter((item) =>
          String(item.user_type)
            .toLowerCase()
            .startsWith(`${filtros.user_type !== undefined ? filtros.user_type : ""}`)
        )
        .filter((item) =>
          String(item.activo)
            .toLowerCase()
            .startsWith(`${filtros.activo !== undefined ? filtros.activo : ""}`)
        )
        
    } else {
      elementos = state.usuarios;
    }

    //console.log("personas", state.personas);

    // ordenar asc y desc
    if (campoOrden !== null && tipoOrden !== null) {
      if (tipoOrden !== null) {
        if (tipoOrden === "asc") {
          elementos = elementos.sort(function (a, b) {
            return String(a[campoOrden])
              .toLowerCase()
              .localeCompare(String(b[campoOrden]).toLowerCase());
          });
        } else {
          elementos = elementos.sort(function (a, b) {
            return String(b[campoOrden])
              .toLowerCase()
              .localeCompare(String(a[campoOrden]).toLowerCase());
          });
        }
      }
    } else {
      // elementos = state.personas;
    }

    dispatch({
      type: ORDENAR_FILTRAR_USUARIOS,
      payload: elementos,
    });
  };
  
  const deseleccionarUsuario = () => {
    dispatch({
      type: DESELECCIONAR_USUARIO,
    });
  };

  const ocultarMensaje = () => {
    dispatch({
      type: REGISTRO_EXITO,
    });
  };

  const actualizarUsuario = (usuario) => {
    //console.log(usuario);
    dispatch({
      type: ACTUALIZAR_USUARIO,
      payload: usuario,
    });
  };

  const eliminarUsuario = (esError, respuesta = null, id) => {
    if (esError) {
      dispatch({
        type: REGISTRO_ERROR,
        payload: "Hubo un error",
      });
    } else {
      dispatch({
        type: ELIMINAR_USUARIO,
        payload: id,
      });
    }
  };

  const eliminarUsuarioII = async (id) => {
    try {
      await clienteAxios.delete("/users", {
        params: { id },
      });
      dispatch({
        type: ELIMINAR_USUARIO,
        payload: id,
      });
    } catch (e) {
      dispatch({
        type: REGISTRO_ERROR,
        payload: "Hubo un error",
      });
    }
  };

  const seleccionarUsuario = (esError, respuesta) => {
    //console.log(respuesta.data.usuario);
    if (esError) {
    } else {
      dispatch({
        type: SELECCIONAR_USUARIO,
        payload: respuesta.data.usuario,
      });
    }
  };

  const obtenerUsuarios = (esError, respuesta) => {
    //console.log(respuesta);
    if (esError) {
      dispatch({
        type: REGISTRO_ERROR,
        payload: respuesta,
      });
    } else {
      dispatch({
        type: OBTENER_USUARIOS,
        payload: respuesta.data.usuarios,
      });
    }
  };

  const obtenerUsuariosII = async (texto) => {
    try {
      dispatch({
        type: CARGANDO,
        payload: true,
      });
      const resultado = await clienteAxios.get("/users", {
        params: { texto },
      });
      dispatch({
        type: OBTENER_USUARIOS,
        payload: resultado.data.usuarios,
      });
    } catch (error) {}
  };


  const registrarUsuario = (esError, respuesta) => {
    const mensaje = {
      msg: esError ? respuesta : "Se Registro el Usuario con Exito. ",
      class: esError ? "danger" : "success",
    };

    dispatch({
      type: REGISTRO_ERROR,
      payload: mensaje, //respuesta.data.msg
    });
  };

  const enviarActivarCuenta = (esError, respuesta) => {
    const mensaje = {
      msg: respuesta.data.msg,
      class: esError ? "danger" : "success",
    };
    dispatch({
      type: REGISTRO_ERROR,
      payload: mensaje,
    });
  };

  const activarCuenta = (esError, respuesta) => {
    const mensaje = {
      msg: esError ? "Hubo un Error o Expiro" : "Se Activo su Cuenta con Exito",
      class: esError ? "danger" : "success",
    };
    dispatch({
      type: REGISTRO_ERROR,
      payload: mensaje,
    });
  };

  const CambiarPassword = (esError, respuesta) => {
    const mensaje = {
      msg: esError ? respuesta : respuesta.data.msg,
      class: esError ? "danger" : "success",
    };
    dispatch({
      type: REGISTRO_ERROR,
      payload: mensaje,
    });
  };

  const OlvidePassword = (esError, respuesta) => {
    const mensaje = {
      msg: esError ? respuesta : respuesta.data.msg,
      class: esError ? "danger" : "success",
    };
    dispatch({
      type: REGISTRO_ERROR,
      payload: mensaje,
    });
  };

  const iniciarSesion = (esError, respuesta) => {
    const mensaje = { msg: respuesta, class: "danger" };
    if (esError) {
      dispatch({
        type: LOGIN_ERROR,
        payload: mensaje,
      });
    } else {
      dispatch({
        type: LOGIN_EXITO,
        payload: respuesta.data.token,
      });
    }
  };

  const usuarioAutenticado = (esError, respuesta) => {
    const mensaje = { msg: respuesta, class: "danger" };
    if (esError) {
      //error.response.data.msg
      dispatch({
        type: LOGIN_ERROR,
        payload: mensaje,
      });
    } else {
      dispatch({
        type: USUARIO_AUTENTICADO,
        payload: respuesta.data,
      });
    }
  };

  const usuarioAutenticadoData = (data) => {
    if (data) {
      dispatch({
        type: USUARIO_AUTENTICADO,
        payload: data,
      });
    }
  };

  const usuarioAutenticadoError = (error) => {
    if (error) {
      dispatch({
        type: LOGIN_ERROR,
        payload: "", //error.response.data.msg,
      });
    }
  };

  // Cerrar la sesión
  const cerrarSesion = () => {
    dispatch({
      type: CERRAR_SESION,
    });
  };

  return (
    <authContext.Provider
      value={{
        token: state.token,
        autenticado: state.autenticado,
        usuario: state.usuario,
        mensaje: state.mensaje,
        usuarios: state.usuarios,
        usuarioSeleccionado: state.usuarioSeleccionado,
        cargando: state.cargando,
        resultado: state.resultado,
        seleccionarUsuario,
        obtenerUsuarios,
        obtenerUsuariosII,
        ocultarMensaje,
        registrarUsuario,
        actualizarUsuario,
        eliminarUsuario,
        eliminarUsuarioII,
        iniciarSesion,
        usuarioAutenticado,
        usuarioAutenticadoData,
        usuarioAutenticadoError,
        cerrarSesion,
        enviarActivarCuenta,
        activarCuenta,
        CambiarPassword,
        OlvidePassword,
        ordenarFiltrar,
        deseleccionarUsuario,
      }}
    >
      {props.children}
    </authContext.Provider>
  );
};

export default AuthState;
