import { useState, useEffect } from "react";
import ReporteAtrasos from "./reporteAtrasos";
import apiAxios from "../../../../config/ApiAxios";
import { Spinner } from "../../../shared";

const ReporteAtrasosController = () => {
  const [loading, setLoading] = useState(false);
  const [data, setData] = useState(null);
  const [sort, setSort] = useState("");
  const [params, setParams] = useState({
    per_page: 30,
    page: 1,
    sort: 'id'
  });

  const onClick = (e, value) => {
    switch (e.target.name) {
      case "sort":
        {
          setData(null)
          setSort(value.sort);
          let reparams = {
            ...params,
            sort: value.sort,
          };
          setParams({
            ...reparams
          })
          fetch(reparams);
        }
        break;
      case "anterior":
        {
          if (params.page !== 1) {
            setLoading(true);
            setParams({
              ...params,
              page: params.page - 1,
            });

            let reparams = {
              ...params,
              page: params.page - 1,
            };

            fetch(reparams);
          }
        }
        break;
      case "siguiente":
        {
          if (params.page < data.last_page) {
            setLoading(true);
            setParams({
              ...params,
              page: params.page + 1,
            });

            let reparams = {
              ...params,
              page: params.page + 1,
            };

            fetch(reparams);
          }
        }
        break;
      case "link":
        {
          setLoading(true);
          setParams({
            ...params,
            page: value,
          });

          let reparams = {
            ...params,
            page: value,
          };

          fetch(reparams);
        }
        break;
    }
  };

  const onChange = (e, value) => {
    switch (e.target.name) {
      case "search":
        {
          let reparams = {
            ...params,
            search: e.target.value,
          };
          setLoading(true);
          setParams({
            ...reparams,
          });
          fetch(reparams);
        }
        break;

      default:
        break;
    }
  };

  const fetch = async (reparams) => {
    let params = {
      ...reparams,
    };

    const result = await apiAxios.get("/personas", { params });

    if (result.status == 200 || result.status == 201) {
      setLoading(false);
      setData(result.data);
    }
  };

  useEffect(() => {
    if (!data) {
      fetch(params);
    }
  }, []);

  useEffect(() => {
    const paramsLocalStorage = localStorage.getItem("persona-seleccionada");
    if(paramsLocalStorage){

    }
  }, [])
  return (
    <ReporteAtrasos
      onClick={onClick}
      onChange={onChange}
      data={data}
      loading={loading}
      sort={sort}
      setSort={setSort}
      params={params}
    />
  );
};

export default ReporteAtrasosController;
