import { useEffect, useState } from "react";
import clienteAxios from "../../../config/axios";
import AlertWarning from "../../CustomAlerts/AlertWarning";
import AlertSuccess from "../../CustomAlerts/AlertSuccess";
import { format, add, sub } from "date-fns";
import { tipoConcesion } from "../../shared/Helpers/CalculosConcesiones";
import { CuotasDetalle } from "../../../functions/Validaciones/CuotasDetalle";

import { CuotaDeuda } from "../../../functions/Validaciones/CuotaDeuda";
import { CalculoCuota } from "../../../functions/FunctionCuota";
import { FormatterNumber } from "../../../utils/formatter";
import Comprobante from "../Modals/Comprobante";
import Modal from "../../../module/modal/Modal";

const dateExtra = () => {
  return format(add(new Date(), { days: 1 }), "yyyy-MM-dd").toString();
};

const Panel = ({
  credito,
  setCredito,
  cuota,
  setCuota,
  pago,
  setPago,
  setSteps,
  steps,
  setForm,
  form,
  persona
}) => {
  //console.log(credito);
  //console.log(cuota);
  //Detalles credito
  const {
    id,
    detalles,
    comentarios,
    concesion,
    cuotas,
    fecha_finalizacion,
    fecha_otorgamiento,
    garante,
    interes_punitorio,
    vencimiento,
    monto,
    producto,
  } = credito;
  //Detalles cuota a pagar
  const {
    nro,
    deuda,
    capital,
    interes,
    diferencia_moratoria,
    interes_mora,
    cantidad_pagos,
    pagos,
    deuda_total,
    fecha_vencimiento,
  } = cuota;

  const [comprobante, setComprobante] = useState(false);
  const [loading, setLoading] = useState(false);
  //console.log(importe_total);
  //console.log(calculo_interes);
  //console.log(total);
  //eventos
  const onClick = async (e) => {
    switch (e.target.name) {
      case "pagar":
        {
          if (form.importe == "" || form.forma_pago.trim() == "") {
            return AlertWarning({
              message: "Complete todos los datos del formulario",
              title: "Advertencia",
            });
          }
          //Validar que no se pague el total
          /*
          if(form.importe > deuda_total){
            return AlertWarning({
              message: "El importe es mayor a la totalidad de la deuda.",
              title: "Advertencia",
            });
          }
          */
          try {
            setLoading(true);
            const result = await clienteAxios.post("/Pago/add", form);
            AlertSuccess({ message: "Pago procesado!" });
            setSteps(steps + 1);
            setPago({
              pago: form,
              cuota: cuota,
            });

            let btn = document.getElementById('btn-comprobante-pago-panel')
            btn.click(); 
            //console.log(form);
            //console.log(cuota);
          } catch (e) {
            setLoading(false);
            AlertWarning({
              message: "",
              title: "Danger",
            });
            return "";
          }

          console.log("aqui");
          return setComprobante(true);
        }
        break;
      case "close":
        {
          setCuota(null);
          setComprobante(false);
        }
        break;
    }
  };

  const onChange = (e) => {
    switch (e.target.name) {
      case "importe":
        {
          let importe = parseFloat(e.target.value);
          let vuelto = 0;
          let importe_cuota = 0;
          let estado = "PARCIAL";
          let prev_estado = "PARCIAL";
          //Excedentes
          let sig_cuotas = null;
          //Credito
          let status_credito = null;

          if (parseFloat(importe).toFixed(2) == parseFloat(deuda).toFixed(2)) {
            estado = "TOTAL";
            prev_estado = "TOTAL";

            //Pago a cuota actual
            importe_cuota = deuda;
          }

          if (
            Number(parseFloat(importe).toFixed(2)) >
            Number(parseFloat(deuda).toFixed(2))
          ) {
            estado = "TOTAL";
            prev_estado = "EXCEDENTE";
            //Pago a cuota actual
            importe_cuota = deuda;

            //Excedente
            let sig_importe = importe - deuda;
            //
            sig_cuotas = [];
            let stop = false;

            //Si es la ultima cuota
            if (cuotas == cuota.nro) {
              prev_estado = "TOTAL";
              estado = "TOTAL";
              importe_cuota = deuda;
              vuelto = importe - deuda;

              //Cancelar Credito
              status_credito = "Pagado";
            }

            cuota.siguientes_cuotas.map((cuota, index) => {
              //Resto
              //console.log("Deuda "+cuota.deuda);
              //console.log("Importe "+sig_importe);

              let resto = sig_importe - cuota.deuda;

              if (resto < 0 && !stop) {
                cuota.estado = "EXCEDENTE";
                cuota.importe = sig_importe;

                sig_cuotas.push(cuota);
                stop = true;
              } else {
                if (!stop) {
                  //Revisar si es la ultima cuota
                  if (cuotas == cuota.nro) {
                    cuota.estado = "TOTAL";
                    cuota.importe = cuota.deuda;

                    sig_cuotas.push(cuota);
                    sig_importe = sig_importe - cuota.deuda;
                    stop = true;

                    //Cancelar Credito
                    status_credito = "Pagado";
                  }

                  //
                  cuota.estado = "TOTAL";
                  cuota.importe = cuota.deuda;

                  sig_cuotas.push(cuota);
                  sig_importe = sig_importe - cuota.deuda;
                }
              }
            });
          }

          if (
            Number(parseFloat(importe).toFixed(2)) <
            Number(parseFloat(deuda).toFixed(2))
          ) {
            estado = "PARCIAL";
            prev_estado = "PARCIAL";
            importe_cuota = importe;
          }

          setForm({
            ...form,
            [e.target.name]: importe,
            importe_cuota: importe_cuota,
            estado: estado,
            prev_estado: prev_estado,
            fecha_importe: format(new Date(), "yyyy-MM-dd").toString(),
            credito_detalle_id: cuota.id,
            id_concesion: concesion ? concesion.id : "",
            credito_id: id,
            nro_cuota: nro,
            total_cuotas: cuotas,
            interes_punitorios: cuota.interes_mora,
            capital: cuota.capital,
            interes: cuota.interes,
            //Cuotas siguientes
            sig_cuotas: JSON.stringify(sig_cuotas),
            //Credito
            status_credito: status_credito,
          });
        }
        break;
      case "forma_pago":
        {
          setForm({
            ...form,
            [e.target.name]: e.target.value,
          });
        }
        break;
      case "judicial":
        {
          setForm({
            ...form,
            [e.target.name]: e.target.checked ? 1 : 0,
          });
        }
        break;
    }
  };
  return (
    <div className="row bg-white shadow px-3" style={{ borderRadius: "20px" }}>
      <div className="col-md-12">
        <div
          className="fw-bold fs-3 mb-1 text-center w-100 py-2"
          style={{
            borderRadius: "20px 20px 20px 20px",
          }}
        >
          PAGAR CREDITO
        </div>
      </div>
      <div className="col-md-12 mb-3 ">
        <div className="d-md-flex justify-content-center pt-3">
          <div class="mb-3 w-100 text-center">
            <label for="exampleFormControlInput1" class="form-label fw-bold">
              TOTAL A PAGAR
            </label>
            <input
              type="text"
              class="form-control bg-white fs-3 text-center fw-bold mx-auto py-3"
              style={{ borderRadius: "20px", width: "400px" }}
              id="exampleFormControlInput1"
              placeholder="0.00"
              value={FormatterNumber(deuda)}
              disabled
            />
          </div>
          <div class="mb-3 w-100">
            <label for="exampleFormControlInput1" class="form-label fw-bold">
              FORMA DE PAGO
            </label>
            <select
              class="form-select fs-4 py-3"
              style={{ borderRadius: "20px" }}
              aria-label="Default select example"
              name="forma_pago"
              onChange={(event) => onChange(event)}
            >
              <option selected></option>
              <option value="efectivo">Efectivo</option>
              <option value="tarjeta">Tarjeta</option>
              <option value="cheque">Cheque</option>
              <option value="Judicial">Judicial</option>
              <option value="Bancario">Bancario</option>
            </select>
          </div>
        </div>

        <div className="d-md-flex">
          <div class="mb-3 w-100 text-center">
            <label for="exampleFormControlInput1" class="form-label fw-bold">
              ESTADO DEL PAGO
            </label>
            <input
              type="text"
              class="form-control bg-white border-0 fs-4 text-center"
              id="exampleFormControlInput1"
              value={form.prev_estado}
              disabled
            />
          </div>
          <div class="mb-3 w-100">
            <label for="exampleFormControlInput1" class="form-label fw-bold">
              IMPORTE
            </label>
            <input
              type="number"
              step="0.00"
              class="form-control fs-4 py-3"
              id="exampleFormControlInput1"
              placeholder="0.00"
              style={{ borderRadius: "20px" }}
              name="importe"
              onChange={(event) => onChange(event)}
              value={form.importe}
            />
          </div>
        </div>
        <div class="mb-3 d-flex justify-content-center">
          <button
            className="fs-4 fw-bold w-100 btn btn-primary rounded-pill shadow-sm"
            name="pagar"
            onClick={(event) => onClick(event)}
          >
            PAGAR
          </button>
        </div>
      </div>

      <div
        className="col-md-3 mb-3 py-2 bg-light"
        style={{ borderRadius: "20px" }}
      >
        <ul class="list-group " style={{ borderRadius: "10px" }}>
          <li class="list-group-item bg-transparent border-0 p-3 d-flex justify-content-start text-uppercase">
            <div className="fs-5 fw-bold">Detalles del credito</div>
          </li>
          <li
            class="list-group-item bg-transparent py-1 border-0 rounded-pill mb-1 d-flex justify-content-between"
          >
            <div className="fs-6 ">Nº Credito</div>
            <div className="fw-bold fs-5">{id}</div>
          </li>
          <li
            class="list-group-item bg-transparent py-1 border-0 rounded-pill mb-1 d-flex justify-content-between"
          >
            <div className="fs-6 ">Nº Cuotas</div>
            <div className="fw-bold fs-5">{cuotas}</div>
          </li>
          <li
            class="list-group-item bg-transparent py-1 border-0 rounded-pill mb-1 d-flex justify-content-between"
          >
            <div className="fs-6 ">Fecha de otorgamiento</div>
            <div className="fw-bold fs-5">
              {format(new Date(fecha_otorgamiento), "dd/MM/yyyy").toString()}
            </div>
          </li>
          <li
            class="list-group-item bg-transparent py-1 border-0 rounded-pill mb-1 d-flex justify-content-between"
          >
            <div className="fs-6 ">Fecha de finalizacion</div>
            <div className="fw-bold fs-5">
              {format(new Date(fecha_finalizacion), "dd/MM/yyyy").toString()}
            </div>
          </li>
          <li
            class="list-group-item bg-transparent py-1 border-0 rounded-pill mb-1 d-flex justify-content-between"
          >
            <div className="fs-6  ">Producto</div>
            <div className="fw-bold fs-5">{producto}</div>
          </li>
          <li
            class="list-group-item bg-transparent py-1 border-0 rounded-pill mb-1 d-flex justify-content-between"
          >
            <div className="fs-6  ">Monto</div>
            <div className="fw-bold fs-5">
              {"$ " + new Intl.NumberFormat("de-DE").format(monto)}
            </div>
          </li>
          <li
            class="list-group-item bg-transparent py-1 border-0 rounded-pill mb-1 d-flex justify-content-between"
          >
            <div className="fs-6  ">Interes punitorio</div>
            <div className="fw-bold fs-5">{interes_punitorio}</div>
          </li>
          <li
            class="list-group-item bg-transparent py-1 border-0 rounded-pill mb-1 d-flex justify-content-between"
          >
            <div className="fs-6  ">Comentarios</div>
            <div className="fw-bold fs-5">{comentarios}</div>
          </li>
          {concesion ? (
            <li
              class="list-group-item bg-transparent border-0"
              style={{
                borderRadius: "20px",
              }}
            >
              <div className="fs-6 fw-bold  w-100 ">
                CONCESION {tipoConcesion(concesion.id)}
              </div>
              <ul class="list-group " style={{ borderRadius: "10px" }}>
                <li class="list-group-item py-1 px-0 bg-transparent border-0 d-flex justify-content-between">
                  <div className="fs-5 ">Fecha de pacto</div>
                  <div className="fs-6 fw-bold">
                    {format(
                      new Date(concesion.fecha_alta),
                      "dd/MM/yyyy"
                    ).toString()}
                  </div>
                </li>
                <li class="list-group-item py-1 px-0 bg-transparent border-0 d-flex justify-content-between">
                  <div className="fs-5 ">Nuevo vencimiento</div>
                  <div className="fs-6 fw-bold">
                    {concesion.inicio_vencimiento == "0000-00-00"
                      ? "Sin plazo"
                      : format(
                        new Date(concesion.inicio_vencimiento),
                        "dd/MM/yyyy"
                      ).toString()}
                  </div>
                </li>
                <li class="list-group-item py-1 px-0 bg-transparent border-0 p-3 d-flex justify-content-between">
                  <div className="fs-5 ">Bonificación</div>
                  <div className="fs-6 fw-bold">
                    {concesion.porcentaje == 0
                      ? "Sin bonificación"
                      : concesion.porcentaje}
                  </div>
                </li>
              </ul>
            </li>
          ) : (
            ""
          )}
        </ul>
      </div>

      {
        <div
          className="col-md-3 mb-3 py-2 px-3  bg-light"
          style={{ borderRadius: "20px" }}
        >
          <ul class="list-group" style={{ borderRadius: "10px" }}>
            <li class="list-group-item bg-transparent border-0 p-3 d-flex justify-content-start">
              <div className="fs-5 fw-bold">DETALLES DE LA CUOTA</div>
            </li>
            <li
              class="list-group-item bg-transparent py-1 border-0 rounded-pill mb-1 d-flex justify-content-between"
            >
              <div className=" fs-6">Nro Cuota</div>
              <div className="fw-bold fs-5">{nro}</div>
            </li>
            <li
              class="list-group-item bg-transparent py-1 border-0 rounded-pill mb-1 d-flex justify-content-between"
            >
              <div className=" fs-6">Vto</div>
              <div className="fw-bold fs-5">
                {format(new Date(fecha_vencimiento), "dd/MM/yyyy").toString()}
              </div>
            </li>
            <li
              class="list-group-item bg-transparent py-1 border-0 rounded-pill mb-1 d-flex justify-content-between"
            >
              <div className=" fs-6">Días de mora</div>
              <div className="fw-bold fs-5">{diferencia_moratoria}</div>
            </li>
            <li
              class="list-group-item bg-transparent border-0 d-flex justify-content-between"
            >
              <div className=" fs-6">CAP</div>
              <div className="fw-bold fs-5">
                {"$ " +
                  new Intl.NumberFormat("de-DE").format(capital.toFixed(2))}
              </div>
              <div className=" fs-6">INT</div>
              <div className="fw-bold fs-5">
                {"$ " +
                  new Intl.NumberFormat("de-DE").format(interes.toFixed(2))}
              </div>
            </li>
            <li
              class="list-group-item bg-transparent  border-0 d-flex justify-content-between"
            >
              <div className=" fs-6">Interes punitorio</div>
              <div className="fw-bold fs-5">
                {"$ " + new Intl.NumberFormat("de-DE").format(interes_mora)}
              </div>
            </li>
            <li
              class="list-group-item bg-transparent  border-0 d-flex justify-content-between"
            >
              <div className=" fs-6">Deuda</div>
              <div className="fw-bold fs-5">
                {"$ " + new Intl.NumberFormat("de-DE").format(deuda)}
              </div>
            </li>
            <li
              class="list-group-item bg-transparent  border-0 d-flex justify-content-between"
            >
              <div className=" fs-6">Cantidad de pagos</div>
              <div className="fw-bold fs-5">{cantidad_pagos}</div>
            </li>
            <li
              class="list-group-item bg-transparent  border-0 d-flex justify-content-between"
            >
              <div className=" fs-6">Importe total</div>
              <div className="fw-bold fs-5">
                {"$ " + new Intl.NumberFormat("de-DE").format(pagos)}
              </div>
            </li>
          </ul>
        </div>
      }


      <button
        className="d-none"
        id="btn-comprobante-pago-panel"
        data-bs-toggle="modal"
        data-bs-target="#comprobante-pago-panel"
      ></button>
      <Modal
        title="Comprobante de pago"
        id="comprobante-pago-panel"
        width={"800px"}
        onClose={{
          id: "btn-close-comprobante-pago-panel",
          name: "close",
          function: onClick,
        }}
        children={
          comprobante ? <Comprobante
            data={pago}
            persona={persona}
            onClick={onClick}
          />
            : ""
        }
      />
    </div>
  );
};

export default Panel;
