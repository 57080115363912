import React from "react";
import { BotonEditar, BotonEliminar } from "../../shared/FormsElements";

const Observacion = ({ item, editar, eliminar, soloLectura }) => {
  return (
    <tr>
      <td className="text-center">{item.date}</td>
      <td className="text-center">{item.autor}</td>
      <td className="text-center">{item.titulo}</td>
      <td className="text-center">{item.observacion}</td>
      <td className="text-center">{item.prioridad}</td>
      <td className="text-center">
        {!soloLectura ? (
          <>
            <button
              className="btn btn-info btn-sm"
              type="button"
              data-bs-toggle="modal"
              data-bs-target="#crear-observacion"
              onClick={() => {
                editar(item);
              }}
            >
              <i className="fa fa-edit" style={{ color: "white" }}></i>
            </button>
            <BotonEliminar eliminar={eliminar} item={item} />
          </>
        ) : null}
      </td>
    </tr>
  );
};

export default Observacion;
