import React from "react";
import { TituloTabla } from "../../shared/Estilos";
import { clienteAxios, useLog, Swal } from "../../shared/";
import { BotonEliminar } from "../../shared/FormsElements";
import { format } from "date-fns";

const ListarCondiciones = ({ lista, setSeleccionado, soloLectura, fetch }) => {
  console.log(lista);

  const editar = (item) => {
    setSeleccionado(item);
  };

  const borrar = async (id) => {};

  const eliminar = (item) => {
    Swal.fire({
      title: "Esta Seguro que desea Eliminar?",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Si, Eliminar",
      cancelButtonText: "Cancelar",
    }).then((result) => {
      if (result.isConfirmed) {
        borrar(item.id);
      }
    });
  };

  return (
    <div className="table-responsive">
      <table
        className="table table-striped"
        style={{ backgroundColor: "white" }}
      >
        <TituloTabla>
          <tr>
            <th scope="col" className="text-center">
              Fecha
            </th>
            <th scope="col" className="text-center">
              Autor
            </th>
            <th scope="col" className="text-center">
              Titulo
            </th>
            <th scope="col" className="text-center">
              Condicion
            </th>
            <th scope="col" className="text-center">
              Acciones
            </th>
          </tr>
        </TituloTabla>
        <tbody>
          {lista.map((item) => (
            <tr>
              <td className="text-center">{`${format(new Date(item.created_at), 'Y/m/d').toString()}`}</td>
              <td className="text-center">
                {`${item.autor.name} ${item.autor.lastname}`}
              </td>
              <td className="text-center">{item.titulo}</td>
              <td className="text-center">{item.condicion}</td>
              <td className="text-center">
                {!soloLectura ? (
                  <>
                    <button
                      className="btn btn-info btn-sm"
                      type="button"
                      data-bs-toggle="modal"
                      data-bs-target="#crear-condicion"
                      onClick={() => {
                        editar(item);
                      }}
                    >
                      <i className="fa fa-edit" style={{ color: "white" }}></i>
                    </button>
                    <BotonEliminar eliminar={eliminar} item={item} />
                  </>
                ) : null}
              </td>
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  );
};

export default ListarCondiciones;
