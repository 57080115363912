import React from "react";
import DeclaracionBien from "./Observacion";
import { TituloTabla } from "../../shared/Estilos";
import { clienteAxios, useLog, Swal } from "../../shared/";

const ListarObservaciones = ({
  lista,
  setSeleccionado,
  soloLectura,
  fetchObservaciones
}) => {

  const [InsertarLog] = useLog(null);

  const borrar = async (id) => {
    try {
      await clienteAxios.delete("/personasobservacion", {
        params: { id },
      });
      InsertarLog( "eliminar", "Eliminacion de Observacion");
      fetchObservaciones();
    } catch (e) {
    }
  };

  const editar = (item) => {
    setSeleccionado(item);
  };

  const eliminar = (item) => {
    Swal.fire({
      title: "Esta Seguro que desea Eliminar?",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Si, Eliminar",
      cancelButtonText: "Cancelar",
    }).then((result) => {
      if (result.isConfirmed) {
        borrar(item.id);
      }
    });
  };

  return (
    <div className="table-responsive">
      <table className="table table-striped" style={{backgroundColor:"white"}}>
        <TituloTabla>
          <tr>
            <th scope="col" className="text-center">
            Fecha
            </th>
            <th scope="col" className="text-center">
            Autor
            </th>
            <th scope="col" className="text-center">
            Titulo
            </th>
            <th scope="col" className="text-center">
            Observacion
            </th>
            <th scope="col" className="text-center">
            Prioridad
            </th>
            <th scope="col" className="text-center">
              Acciones
            </th>
          </tr>
        </TituloTabla>
        <tbody>
          {lista.map((item) => (
            <DeclaracionBien
              key={item.id}
              item={item}
              editar={editar}
              eliminar={eliminar}
              soloLectura={soloLectura}
            />
          ))}
        </tbody>
      </table>
    </div>
  );
};

export default ListarObservaciones;
