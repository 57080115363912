import { format, add, sub } from "date-fns";
import { log } from "util";
import { CuotaDeuda } from "./Validaciones/CuotaDeuda";
import { CuotasDetalle } from "./Validaciones/CuotasDetalle";
import clienteAxios from "../config/axios";

const CalculoMoratoria = (
  interes_mora,
  capital,

  fecha_actual,
  fecha_vencimiento
) => {
  //Punitorios
  let interes_punitorio = 0;
  let diferencia_moratoria = 0;

  //Formatear fecha actual
  fecha_actual = format(fecha_actual, "yyyy-MM-dd").toString();

  //console.log(fecha_actual);

  fecha_actual = new Date(fecha_actual + " 00:00:00").getTime();

  //console.log(fecha_vencimiento);

  //Fecha de vencimiento de cuota
  let vto = new Date(fecha_vencimiento + " 00:00:00").getTime();

  //Calculo de moratoria
  diferencia_moratoria = fecha_actual - vto;
  diferencia_moratoria = diferencia_moratoria / (1000 * 60 * 60 * 24);

  if (diferencia_moratoria > 0) {
    interes_punitorio = capital * (interes_mora / 100);
    interes_punitorio = interes_punitorio / 30;

    //intereses punitorios
    interes_punitorio = interes_punitorio * diferencia_moratoria;
  }

  //console.log(interes_punitorio);
  //console.log(diferencia_moratoria);

  return {
    p_interes: interes_punitorio,
    p_moratoria: diferencia_moratoria,
  };
};

export const CalculoCuota = (cuota) => {
  console.log(cuota);
  //Propiedades
  const {
    //concesion
    bonificacion,
    bonificacion_concesion,
    concesion_fecha_vencimiento,
    fecha_alta_concesion,
    //cuota
    capital,
    credito_id,
    estado,
    fecha_vencimiento,
    id,
    interes,
    interes_mora,
    interes_punitorio,
    pagos,
    total,
  } = cuota;

  let re_interes_punitorio = 0;
  let diferencia_dias = 0;
  let real_interes_punitorio = 0;
  let re_interes = parseFloat(interes);
  let re_capital = parseFloat(capital);
  let fecha_actual = fecha_alta_concesion
    ? new Date(fecha_alta_concesion + " 00:00:00")
    : new Date();
  let re_fecha_vencimiento = fecha_vencimiento;
  let deuda = 0;
  let deuda_total = 0;
  let re_bonificacion = bonificacion_concesion ? bonificacion_concesion : 0;
  let cantidad_pagos = 0;
  let total_pagos = 0;
  let fecha_importe = "";
  let history = "";
  let existe_total = false;

  pagos.map((p, index) => {
    cantidad_pagos = cantidad_pagos + 1;
    total_pagos = parseFloat(p.importe) + total_pagos;

    fecha_importe = fecha_alta_concesion
      ? new Date(fecha_alta_concesion + " 00:00:00")
      : new Date(p.fecha_importe + " 00:00:00");

    /* Si es el primer pago a la cuota */
    //if ((index = 0 && !fecha_alta_concesion)) {
    //  fecha_importe = fecha_actual;
    //}

    /* Si el pago  */

    let importe = p.importe;

    switch (p.estado) {
      case "EXCEDENTE":
        {
          /* 
          Si la fecha de importe es mayor a la fecha de vencimiento 
          Entonces 
         */

          //let p_interes = 0;
          //let p_moratoria = 0;

          let { p_interes, p_moratoria } = CalculoMoratoria(
            parseFloat(interes_mora) + parseFloat(interes_punitorio),
            re_capital,

            //fecha_importe,
            fecha_importe,
            re_fecha_vencimiento
          );

          /* Seteo interes punitorio */
          if (p_interes > 0) {
            if (re_interes_punitorio > 0) {
              re_interes_punitorio = p_interes + re_interes_punitorio;
            } else {
              re_interes_punitorio = p_interes;
            }
            real_interes_punitorio = p_interes + real_interes_punitorio;
          }

          /* Seteo diferencia de dias */
          if (p_moratoria > 0) {
            diferencia_dias = p_moratoria + diferencia_dias;
          }

          history +=
            "<li class=' list-group-item border-0 text-nowrap'>INGRESO | IMPORTE: $" +
            new Intl.NumberFormat("de-DE").format(
              parseFloat(importe).toFixed(2)
            ) +
            " EXCEDENTE " +
            format(fecha_importe, "yyyy-MM-dd").toString() +
            "</li><li class='bg-white list-group-item border-0 text-nowrap'>CUOTA | CAPITAL: $" +
            new Intl.NumberFormat("de-DE").format(
              parseFloat(re_capital < 0 ? 0 : re_capital).toFixed(2)
            ) +
            " INT: $" +
            new Intl.NumberFormat("de-DE").format(
              parseFloat(re_interes < 0 ? 0 : re_interes).toFixed(2)
            ) +
            " IP:$" +
            new Intl.NumberFormat("de-DE").format(
              parseFloat(p_interes < 0 ? 0 : p_interes).toFixed(2)
            ) +
            " D:" +
            (p_moratoria < 0 ? 0 : p_moratoria) +
            "</li><li class='bg-white list-group-item border-0 text-nowrap'>DIAS ACUMULADOS " +
            diferencia_dias +
            "</li>";

          /* Resto */
          /* 1 Interes punitorio */
          re_interes_punitorio =
            (re_interes_punitorio < 0 ? 0 : re_interes_punitorio) -
            (re_interes_punitorio / 100) * re_bonificacion -
            importe;

          /* 2 Interes */
          if (re_interes_punitorio < 0 && re_interes > 0) {
            re_interes =
              re_interes -
              (re_interes / 100) * re_bonificacion +
              re_interes_punitorio;
          } else {
            if (re_interes_punitorio < 0) {
              re_interes = re_interes_punitorio;
            }
          }

          /* 3 Capital */
          if (re_interes < 0) {
            re_capital =
              re_capital - (re_capital / 100) * re_bonificacion + re_interes;
          }

          history +=
            "<li class='list-group-item text-nowrap border-top-0 border-end-0 border-start-0'>SALDO | CAPITAL: $" +
            new Intl.NumberFormat("de-DE").format(
              parseFloat(re_capital < 0 ? 0 : re_capital).toFixed(2)
            ) +
            " INT: $" +
            new Intl.NumberFormat("de-DE").format(
              parseFloat(re_interes < 0 ? 0 : re_interes).toFixed(2)
            ) +
            " IP: $" +
            new Intl.NumberFormat("de-DE").format(
              parseFloat(
                re_interes_punitorio < 0 ? 0 : re_interes_punitorio
              ).toFixed(2)
            ) +
            " D: " +
            (p_moratoria < 0 ? 0 : p_moratoria) +
            "</li>";

          if (new Date(fecha_importe) > new Date(fecha_vencimiento)) {
            re_fecha_vencimiento = format(
              fecha_importe,
              "yyyy-MM-dd"
            ).toString();
          }
        }
        break;
      case "PARCIAL":
        {
          /* 
          Se calcula interes de acuerdo a la fecha de importe
          */

          let { p_interes, p_moratoria } = CalculoMoratoria(
            parseFloat(interes_mora) + parseFloat(interes_punitorio),
            re_capital,

            fecha_importe,
            re_fecha_vencimiento
          );

          /* Seteo interes punitorio */
          if (p_interes > 0) {
            if (re_interes_punitorio > 0) {
              re_interes_punitorio = p_interes + re_interes_punitorio;
            } else {
              re_interes_punitorio = p_interes;
            }
            real_interes_punitorio = p_interes + real_interes_punitorio;
          }

          /* Seteo diferencia de dias */
          if (p_moratoria > 0) {
            diferencia_dias = p_moratoria + diferencia_dias;
          }

          history +=
            "<li class=' list-group-item border-0 text-nowrap'>INGRESO | IMPORTE: $" +
            new Intl.NumberFormat("de-DE").format(
              parseFloat(importe).toFixed(2)
            ) +
            " PARCIAL " +
            format(fecha_importe, "yyyy-MM-dd").toString() +
            "</li><li class='bg-white list-group-item border-0 text-nowrap'>CUOTA | CAPITAL: $" +
            new Intl.NumberFormat("de-DE").format(
              parseFloat(re_capital < 0 ? 0 : re_capital).toFixed(2)
            ) +
            " INT: $" +
            new Intl.NumberFormat("de-DE").format(
              parseFloat(re_interes < 0 ? 0 : re_interes).toFixed(2)
            ) +
            " IP:$" +
            new Intl.NumberFormat("de-DE").format(
              parseFloat(p_interes < 0 ? 0 : p_interes).toFixed(2)
            ) +
            " D:" +
            (p_moratoria < 0 ? 0 : p_moratoria) +
            " TOTAL: " +
            new Intl.NumberFormat("de-DE").format(
              parseFloat(
                (re_interes < 0 ? 0 : re_interes) +
                  (re_capital < 0 ? 0 : re_capital) +
                  (p_interes < 0 ? 0 : p_interes)
              ).toFixed(2)
            ) +
            "</li><li class='bg-white list-group-item border-0 text-nowrap'>DIAS ACUMULADOS " +
            diferencia_dias +
            "</li>";

          /* Resto */
          /* 1 Interes punitorio */

          re_interes_punitorio =
            (re_interes_punitorio < 0 ? 0 : re_interes_punitorio) -
            importe -
            (re_interes_punitorio / 100) * re_bonificacion;

          /* 2 Interes */
          if (re_interes_punitorio < 0 && re_interes > 0) {
            re_interes =
              re_interes -
              (re_interes / 100) * re_bonificacion +
              re_interes_punitorio;
          } else {
            if (re_interes_punitorio < 0) {
              re_interes = re_interes_punitorio;
            }
          }

          /* 3 Capital */
          if (re_interes < 0) {
            re_capital =
              re_capital - (re_capital / 100) * re_bonificacion + re_interes;
          }

          if (new Date(fecha_importe) > new Date(fecha_vencimiento)) {
            re_fecha_vencimiento = format(
              fecha_importe,
              "yyyy-MM-dd"
            ).toString();
          }

          history +=
            "<li class='list-group-item text-nowrap border-top-0 border-end-0 border-start-0'>SALDO | CAPITAL: $" +
            new Intl.NumberFormat("de-DE").format(
              parseFloat(re_capital < 0 ? 0 : re_capital).toFixed(2)
            ) +
            " INT: $" +
            new Intl.NumberFormat("de-DE").format(
              parseFloat(re_interes < 0 ? 0 : re_interes).toFixed(2)
            ) +
            " IP: $" +
            new Intl.NumberFormat("de-DE").format(
              parseFloat(
                re_interes_punitorio < 0 ? 0 : re_interes_punitorio
              ).toFixed(2)
            ) +
            " D: " +
            (p_moratoria < 0 ? 0 : p_moratoria) +
            " TOTAL: " +
            new Intl.NumberFormat("de-DE").format(
              parseFloat(
                (re_interes < 0 ? 0 : re_interes) +
                  (re_capital < 0 ? 0 : re_capital)
              ).toFixed(2)
            ) +
            "</li>";

          //re_fecha_vencimiento = format(fecha_importe, "yyyy-MM-dd").toString();
        }
        break;
      case "TOTAL":
        {
          existe_total = true;
          /* 
        Si la fecha de importe es mayor a la fecha de vencimiento 
        Entonces 
        */

          let { p_interes, p_moratoria } = CalculoMoratoria(
            parseFloat(interes_mora) + parseFloat(interes_punitorio),
            re_capital,

            fecha_importe,
            re_fecha_vencimiento
          );

          /* Seteo interes punitorio */
          if (p_interes > 0) {
            if (re_interes_punitorio > 0) {
              re_interes_punitorio = p_interes + re_interes_punitorio;
            } else {
              re_interes_punitorio = p_interes;
            }
            real_interes_punitorio = p_interes + real_interes_punitorio;
          }

          /* Seteo diferencia de dias */
          if (p_moratoria > 0) {
            diferencia_dias = p_moratoria + diferencia_dias;
          }

          history +=
            "<li class=' list-group-item border-0 text-nowrap'>INGRESO | IMPORTE: $" +
            new Intl.NumberFormat("de-DE").format(
              parseFloat(importe).toFixed(2)
            ) +
            " TOTAL " +
            format(fecha_importe, "yyyy-MM-dd").toString() +
            "</li><li class='bg-white list-group-item border-0 text-nowrap'>CUOTA | CAPITAL: $" +
            new Intl.NumberFormat("de-DE").format(
              parseFloat(re_capital < 0 ? 0 : re_capital).toFixed(2)
            ) +
            " INT: $" +
            new Intl.NumberFormat("de-DE").format(
              parseFloat(re_interes < 0 ? 0 : re_interes).toFixed(2)
            ) +
            " IP:$" +
            new Intl.NumberFormat("de-DE").format(
              parseFloat(p_interes < 0 ? 0 : p_interes).toFixed(2)
            ) +
            " D:" +
            (p_moratoria < 0 ? 0 : p_moratoria) +
            "</li><li class='bg-white list-group-item border-0 text-nowrap'>DIAS ACUMULADOS " +
            diferencia_dias +
            "</li>";

          /* Resto */
          /* 1 Interes punitorio */
          re_interes_punitorio =
            (re_interes_punitorio < 0 ? 0 : re_interes_punitorio) -
            (re_interes_punitorio / 100) * re_bonificacion -
            importe;

          /* 2 Interes */
          if (re_interes_punitorio < 0 && re_interes > 0) {
            re_interes =
              re_interes -
              (re_interes / 100) * re_bonificacion +
              re_interes_punitorio;
          } else {
            if (re_interes_punitorio < 0) {
              re_interes = re_interes_punitorio;
            }
          }

          /* 3 Capital */
          if (re_interes < 0) {
            re_capital =
              re_capital - (re_capital / 100) * re_bonificacion + re_interes;
          }

          history +=
            "<li class='list-group-item text-nowrap border-top-0 border-end-0 border-start-0'>SALDO | CAPITAL: $" +
            new Intl.NumberFormat("de-DE").format(
              parseFloat(re_capital < 0 ? 0 : re_capital).toFixed(2)
            ) +
            " INT: $" +
            new Intl.NumberFormat("de-DE").format(
              parseFloat(re_interes < 0 ? 0 : re_interes).toFixed(2)
            ) +
            " IP: $" +
            new Intl.NumberFormat("de-DE").format(
              parseFloat(
                re_interes_punitorio < 0 ? 0 : re_interes_punitorio
              ).toFixed(2)
            ) +
            " D: " +
            (p_moratoria < 0 ? 0 : p_moratoria) +
            "</li>";

          //re_fecha_vencimiento = format(fecha_importe, "yyyy-MM-dd").toString();
          if (new Date(fecha_importe) > new Date(fecha_vencimiento)) {
            re_fecha_vencimiento = format(
              fecha_importe,
              "yyyy-MM-dd"
            ).toString();
          }
        }
        break;
    }
  });

  //console.log(parseFloat(interes_mora));
  //console.log(parseFloat(interes_punitorio));

  if (parseFloat(re_capital) > 0.8 && pagos.length > 0) {
    let { p_interes, p_moratoria } = CalculoMoratoria(
      parseFloat(interes_mora) + parseFloat(interes_punitorio),
      re_capital,

      fecha_actual,
      re_fecha_vencimiento
    );

    if (p_interes > 0) {
      if (re_interes_punitorio > 0) {
        re_interes_punitorio = p_interes + re_interes_punitorio;
      } else {
        re_interes_punitorio = p_interes;
      }
      real_interes_punitorio = p_interes + real_interes_punitorio;
    }

    /* Seteo diferencia de dias */
    if (p_moratoria > 0) {
      diferencia_dias = p_moratoria + diferencia_dias;
    }
  }

  if (pagos.length == 0) {
    let { p_interes, p_moratoria } = CalculoMoratoria(
      parseFloat(interes_mora) + parseFloat(interes_punitorio),
      re_capital,

      fecha_actual,
      fecha_vencimiento
    );


    /* Seteo interes punitorio */
    if (p_interes > 0) {
      re_interes_punitorio = p_interes;
      real_interes_punitorio = p_interes;
    }

    /* Seteo diferencia de dias */
    if (p_moratoria > 0) {
      diferencia_dias = p_moratoria + diferencia_dias;
    }

    if (re_bonificacion) {
      re_interes_punitorio =
        re_interes_punitorio - (re_interes_punitorio / 100) * re_bonificacion;
      re_interes = re_interes - (re_interes / 100) * re_bonificacion;
      re_capital = re_capital - (re_capital / 100) * re_bonificacion;
    }
  }

  re_interes_punitorio = re_interes_punitorio < 0 ? 0 : re_interes_punitorio;
  re_interes = re_interes < 0 ? 0 : re_interes;
  re_capital = re_capital < 0 ? 0 : re_capital;
  deuda = re_capital + re_interes + re_interes_punitorio;

  return {
    //Valores originales sin modificar
    original_capital: new Intl.NumberFormat("de-DE").format(capital),
    original_interes: new Intl.NumberFormat("de-DE").format(interes),
    original_deuda: new Intl.NumberFormat("de-DE").format(
      parseFloat(capital) + parseFloat(interes)
    ),

    original_interes_punitorio: real_interes_punitorio,
    //original_interes_punitorio: p_interes,

    //punitorios
    interes_punitorio: re_interes_punitorio,
    diferencia_moratoria: diferencia_dias,

    //cuota
    capital: re_capital,
    interes: re_interes,
    deuda: deuda,
    //excedente: JSON.stringify(excedente),

    //Pagos
    cantidad_pagos: cantidad_pagos,
    pagos: total_pagos,
    deuda_total: 0,

    //
    historia: history,
  };
};

export const InsertCuotas = (pagos, excedente) => {
  let result = [];

  if (excedente) {
    if (excedente.status == "true") {
      result.push(excedente);
    }
  }

  pagos.map((pago) => {
    result.push(pago);
  });

  pagos = result;
  return pagos;
};

export const SumaExcedentes = (excedente) => {
  let suma = 0;

  if (excedente) {
    if (excedente.status == "true") {
      suma = excedente.importe + suma;
    }
  }

  return suma;
};

export const PagarCuota = (cuotas) => {
  //ordenar cuotas por fecha de vencimiento
  let sortCuotas = cuotas.sort(
    (a, b) =>
      new Date(a.fecha_vencimiento).getTime() >
      new Date(b.fecha_vencimiento).getTime()
  );

  let excedente = null;

  for (let i = 0; i < sortCuotas.length; i++) {
    let {
      capital,
      interes,
      deuda,
      interes_punitorio,
      diferencia_moratoria,
      cantidad_pagos,
      pagos,
    } = CalculoCuota(sortCuotas[i]);

    if (parseFloat(capital.toFixed(0)) > 0) {
      let sig_cuotas = [];

      sortCuotas.map((cuota, index) => {
        if (index > i) {
          let result = CalculoCuota(cuota);
          result.id = cuota.id;
          result.nro = index + 1;

          sig_cuotas.push(result);
        }
      });

      let deuda_total = CalcularDeudaTotal(deuda, sig_cuotas);

      return {
        id: sortCuotas[i].id,
        nro: i + 1,
        deuda: deuda,
        capital: capital,
        interes: interes,
        diferencia_moratoria: diferencia_moratoria,
        interes_mora: interes_punitorio,
        cantidad_pagos: cantidad_pagos,
        pagos: pagos,
        id_credito: sortCuotas[i].credito_id,
        fecha_vencimiento: sortCuotas[i].fecha_vencimiento,
        deuda_total: deuda_total,
        //Cuota anteior
        anterior_cuota: {
          id: i > 0 ? sortCuotas[i - 1].id : null,
          fecha_vencimiento: i > 0 ? sortCuotas[i].fecha_vencimiento : null,
        },
        //Cuota siguiente
        siguientes_cuotas: sig_cuotas,
      };
      break;
    }
  }
};

export const CalcularDeudaTotal = (deuda, cuotas) => {
  let result = deuda;

  cuotas.map((cuota) => {
    result = cuota.deuda + result;
  });

  return result;
};

/*bug */

const modificar = async (pago) => {
  try {
    const result = await clienteAxios.post("/Pago/update", pago);
  } catch (e) {
    return console.log("error");
  }
};

const agregar_pago = async (pago) => {
  try {
    const result = await clienteAxios.post("/Pago/add", pago);
  } catch (e) {
    return console.log("error");
  }
};

const calcular_cuota_corregir = (cuota) => {
  //Propiedades
  const {
    //concesion
    bonificacion,
    bonificacion_concesion,
    concesion_fecha_vencimiento,
    fecha_alta_concesion,
    //cuota
    capital,
    credito_id,
    estado,
    fecha_vencimiento,
    id,
    interes,
    interes_mora,
    interes_punitorio,
    pagos,
    total,
  } = cuota;

  let re_interes_punitorio = 0;
  let diferencia_dias = 0;
  let real_interes_punitorio = 0;
  let re_interes = parseFloat(interes);
  let re_capital = parseFloat(capital);
  let fecha_actual = fecha_alta_concesion
    ? new Date(fecha_alta_concesion + " 00:00:00")
    : new Date();
  let re_fecha_vencimiento = fecha_vencimiento;
  let deuda = 0;
  let deuda_total = 0;
  let re_bonificacion = bonificacion ? bonificacion : 0;
  let cantidad_pagos = 0;
  let total_pagos = 0;
  let fecha_importe = "";
  let history = "";
  let array_pagos = [];

  pagos.map((p, index) => {
    cantidad_pagos = cantidad_pagos + 1;
    total_pagos = parseFloat(p.importe) + total_pagos;

    fecha_importe = fecha_alta_concesion
      ? new Date(fecha_alta_concesion + " 00:00:00")
      : new Date(p.fecha_importe + " 00:00:00");

    /* Si es el primer pago a la cuota */
    if ((index = 0 && !fecha_alta_concesion)) {
      fecha_importe = fecha_actual;
    }

    /* Si el pago  */

    let importe = p.importe;

    switch (p.estado) {
      case "EXCEDENTE":
        {
          /* 
          Si la fecha de importe es mayor a la fecha de vencimiento 
          Entonces 
         */

          let { p_interes, p_moratoria } = CalculoMoratoria(
            parseFloat(interes_mora) + parseFloat(interes_punitorio),
            re_capital,

            //fecha_importe,
            fecha_importe,
            re_fecha_vencimiento
          );

          history +=
            "<li class=' list-group-item border-0 text-nowrap'>INGRESO | IMPORTE: $" +
            new Intl.NumberFormat("de-DE").format(
              parseFloat(importe).toFixed(2)
            ) +
            " PARCIAL " +
            format(fecha_importe, "yyyy-MM-dd").toString() +
            "</li><li class='bg-white list-group-item border-0 text-nowrap'>CUOTA | CAPITAL: $" +
            new Intl.NumberFormat("de-DE").format(
              parseFloat(re_capital < 0 ? 0 : re_capital).toFixed(2)
            ) +
            " INT: $" +
            new Intl.NumberFormat("de-DE").format(
              parseFloat(re_interes < 0 ? 0 : re_interes).toFixed(2)
            ) +
            " IP:$" +
            new Intl.NumberFormat("de-DE").format(
              parseFloat(p_interes < 0 ? 0 : p_interes).toFixed(2)
            ) +
            " D:" +
            (p_moratoria < 0 ? 0 : p_moratoria) +
            "</li>";

          /* Seteo interes punitorio */
          if (p_interes > 0) {
            if (re_interes_punitorio > 0) {
              re_interes_punitorio = p_interes + re_interes_punitorio;
            } else {
              re_interes_punitorio = p_interes;
            }
            real_interes_punitorio = p_interes + real_interes_punitorio;
          }

          /* Seteo diferencia de dias */
          if (p_moratoria > 0) {
            diferencia_dias = p_moratoria + diferencia_dias;
          }

          /* Resto */
          /* 1 Interes punitorio */
          re_interes_punitorio =
            (re_interes_punitorio < 0 ? 0 : re_interes_punitorio) -
            (re_interes_punitorio / 100) * re_bonificacion -
            importe;

          /* 2 Interes */
          if (re_interes_punitorio < 0 && re_interes > 0) {
            re_interes =
              re_interes -
              (re_interes / 100) * re_bonificacion +
              re_interes_punitorio;
          } else {
            if (re_interes_punitorio < 0) {
              re_interes = re_interes_punitorio;
            }
          }

          /* 3 Capital */
          if (re_interes < 0) {
            re_capital =
              re_capital - (re_capital / 100) * re_bonificacion + re_interes;
          }

          history +=
            "<li class='list-group-item text-nowrap border-top-0 border-end-0 border-start-0'>SALDO | CAPITAL: $" +
            new Intl.NumberFormat("de-DE").format(
              parseFloat(re_capital < 0 ? 0 : re_capital).toFixed(2)
            ) +
            " INT: $" +
            new Intl.NumberFormat("de-DE").format(
              parseFloat(re_interes < 0 ? 0 : re_interes).toFixed(2)
            ) +
            " IP: $" +
            new Intl.NumberFormat("de-DE").format(
              parseFloat(
                re_interes_punitorio < 0 ? 0 : re_interes_punitorio
              ).toFixed(2)
            ) +
            " D: " +
            (p_moratoria < 0 ? 0 : p_moratoria) +
            "</li>";

          if (new Date(fecha_importe) > new Date(re_fecha_vencimiento)) {
            re_fecha_vencimiento = format(
              fecha_importe,
              "yyyy-MM-dd"
            ).toString();
          }

          array_pagos.push(p);
        }
        break;
      case "PARCIAL":
        {
          /* 
          Se calcula interes de acuerdo a la fecha de importe
          */

          let { p_interes, p_moratoria } = CalculoMoratoria(
            parseFloat(interes_mora) + parseFloat(interes_punitorio),
            re_capital,

            fecha_importe,
            re_fecha_vencimiento
          );

          history +=
            "<li class=' list-group-item border-0 text-nowrap'>INGRESO | IMPORTE: $" +
            new Intl.NumberFormat("de-DE").format(
              parseFloat(importe).toFixed(2)
            ) +
            " PARCIAL " +
            format(fecha_importe, "yyyy-MM-dd").toString() +
            "</li><li class='bg-white list-group-item border-0 text-nowrap'>CUOTA | CAPITAL: $" +
            new Intl.NumberFormat("de-DE").format(
              parseFloat(re_capital < 0 ? 0 : re_capital).toFixed(2)
            ) +
            " INT: $" +
            new Intl.NumberFormat("de-DE").format(
              parseFloat(re_interes < 0 ? 0 : re_interes).toFixed(2)
            ) +
            " IP:$" +
            new Intl.NumberFormat("de-DE").format(
              parseFloat(p_interes < 0 ? 0 : p_interes).toFixed(2)
            ) +
            " D:" +
            (p_moratoria < 0 ? 0 : p_moratoria) +
            " TOTAL: " +
            new Intl.NumberFormat("de-DE").format(
              parseFloat(
                (re_interes < 0 ? 0 : re_interes) +
                  (re_capital < 0 ? 0 : re_capital)
              ).toFixed(2)
            ) +
            "</li>";

          /* Seteo interes punitorio */
          if (p_interes > 0) {
            if (re_interes_punitorio > 0) {
              re_interes_punitorio = p_interes + re_interes_punitorio;
            } else {
              re_interes_punitorio = p_interes;
            }
            real_interes_punitorio = p_interes + real_interes_punitorio;
          }

          /* Seteo diferencia de dias */
          if (p_moratoria > 0) {
            diferencia_dias = p_moratoria + diferencia_dias;
          }

          /* Resto */
          /* 1 Interes punitorio */

          re_interes_punitorio =
            (re_interes_punitorio < 0 ? 0 : re_interes_punitorio) -
            importe -
            (re_interes_punitorio / 100) * re_bonificacion;

          /* 2 Interes */
          if (re_interes_punitorio < 0 && re_interes > 0) {
            re_interes =
              re_interes -
              (re_interes / 100) * re_bonificacion +
              re_interes_punitorio;
          } else {
            if (re_interes_punitorio < 0) {
              re_interes = re_interes_punitorio;
            }
          }

          /* 3 Capital */
          if (re_interes < 0) {
            re_capital =
              re_capital - (re_capital / 100) * re_bonificacion + re_interes;
          }

          if (new Date(fecha_importe) > new Date(re_fecha_vencimiento)) {
            re_fecha_vencimiento = format(
              fecha_importe,
              "yyyy-MM-dd"
            ).toString();
          }

          history +=
            "<li class='list-group-item text-nowrap border-top-0 border-end-0 border-start-0'>SALDO | CAPITAL: $" +
            new Intl.NumberFormat("de-DE").format(
              parseFloat(re_capital < 0 ? 0 : re_capital).toFixed(2)
            ) +
            " INT: $" +
            new Intl.NumberFormat("de-DE").format(
              parseFloat(re_interes < 0 ? 0 : re_interes).toFixed(2)
            ) +
            " IP: $" +
            new Intl.NumberFormat("de-DE").format(
              parseFloat(
                re_interes_punitorio < 0 ? 0 : re_interes_punitorio
              ).toFixed(2)
            ) +
            " D: " +
            (p_moratoria < 0 ? 0 : p_moratoria) +
            " TOTAL: " +
            new Intl.NumberFormat("de-DE").format(
              parseFloat(
                (re_interes < 0 ? 0 : re_interes) +
                  (re_capital < 0 ? 0 : re_capital)
              ).toFixed(2)
            ) +
            "</li>";

          //re_fecha_vencimiento = format(fecha_importe, "yyyy-MM-dd").toString();
          array_pagos.push(p);
        }
        break;
      case "TOTAL":
        {
          /* 
        Si la fecha de importe es mayor a la fecha de vencimiento 
        Entonces 
        */

          let { p_interes, p_moratoria } = CalculoMoratoria(
            parseFloat(interes_mora) + parseFloat(interes_punitorio),
            re_capital,

            fecha_importe,
            //fecha_importe,
            re_fecha_vencimiento
          );

          history +=
            "<li class=' list-group-item border-0 text-nowrap'>INGRESO | IMPORTE: $" +
            new Intl.NumberFormat("de-DE").format(
              parseFloat(importe).toFixed(2)
            ) +
            " PARCIAL " +
            format(fecha_importe, "yyyy-MM-dd").toString() +
            "</li><li class='bg-white list-group-item border-0 text-nowrap'>CUOTA | CAPITAL: $" +
            new Intl.NumberFormat("de-DE").format(
              parseFloat(re_capital < 0 ? 0 : re_capital).toFixed(2)
            ) +
            " INT: $" +
            new Intl.NumberFormat("de-DE").format(
              parseFloat(re_interes < 0 ? 0 : re_interes).toFixed(2)
            ) +
            " IP:$" +
            new Intl.NumberFormat("de-DE").format(
              parseFloat(p_interes < 0 ? 0 : p_interes).toFixed(2)
            ) +
            " D:" +
            (p_moratoria < 0 ? 0 : p_moratoria) +
            "</li>";

          /* Seteo interes punitorio */
          /* Seteo interes punitorio */
          if (p_interes > 0) {
            if (re_interes_punitorio > 0) {
              re_interes_punitorio = p_interes + re_interes_punitorio;
            } else {
              re_interes_punitorio = p_interes;
            }
            real_interes_punitorio = p_interes + real_interes_punitorio;
          }

          /* Seteo diferencia de dias */
          if (p_moratoria > 0) {
            diferencia_dias = p_moratoria;
          }

          /* Resto */
          /* 1 Interes punitorio */
          re_interes_punitorio =
            (re_interes_punitorio < 0 ? 0 : re_interes_punitorio) -
            (re_interes_punitorio / 100) * re_bonificacion -
            importe;

          /* 2 Interes */
          if (re_interes_punitorio < 0 && re_interes > 0) {
            re_interes =
              re_interes -
              (re_interes / 100) * re_bonificacion +
              re_interes_punitorio;
          } else {
            if (re_interes_punitorio < 0) {
              re_interes = re_interes_punitorio;
            }
          }

          /* 3 Capital */
          if (re_interes < 0) {
            re_capital =
              re_capital - (re_capital / 100) * re_bonificacion + re_interes;
          }

          history +=
            "<li class='list-group-item text-nowrap border-top-0 border-end-0 border-start-0'>SALDO | CAPITAL: $" +
            new Intl.NumberFormat("de-DE").format(
              parseFloat(re_capital < 0 ? 0 : re_capital).toFixed(2)
            ) +
            " INT: $" +
            new Intl.NumberFormat("de-DE").format(
              parseFloat(re_interes < 0 ? 0 : re_interes).toFixed(2)
            ) +
            " IP: $" +
            new Intl.NumberFormat("de-DE").format(
              parseFloat(
                re_interes_punitorio < 0 ? 0 : re_interes_punitorio
              ).toFixed(2)
            ) +
            " D: " +
            (p_moratoria < 0 ? 0 : p_moratoria) +
            "</li>";

          re_fecha_vencimiento = format(fecha_importe, "yyyy-MM-dd").toString();
          array_pagos.push(p);
        }
        break;
    }
  });

  //console.log(parseFloat(interes_mora));
  //console.log(parseFloat(interes_punitorio));

  if (pagos.length == 0) {
    let { p_interes, p_moratoria } = CalculoMoratoria(
      parseFloat(interes_mora) + parseFloat(interes_punitorio),
      re_capital,

      fecha_actual,
      fecha_vencimiento
    );

    //console.log(p_interes)

    /* Seteo interes punitorio */
    if (p_interes > 0) {
      re_interes_punitorio = p_interes;
      real_interes_punitorio = p_interes;
    }

    /* Seteo diferencia de dias */
    if (p_moratoria > 0) {
      diferencia_dias = p_moratoria + diferencia_dias;
    }

    if (bonificacion) {
      re_interes_punitorio =
        re_interes_punitorio - (re_interes_punitorio / 100) * bonificacion;
      re_interes = re_interes - (re_interes / 100) * bonificacion;
      re_capital = re_capital - (re_capital / 100) * bonificacion;
    }
  }

  re_interes_punitorio = re_interes_punitorio < 0 ? 0 : re_interes_punitorio;
  re_interes = re_interes < 0 ? 0 : re_interes;
  re_capital = re_capital < 0 ? 0 : re_capital;
  deuda = re_capital + re_interes + re_interes_punitorio;

  return {
    //Valores originales sin modificar
    original_capital: new Intl.NumberFormat("de-DE").format(capital),
    original_interes: new Intl.NumberFormat("de-DE").format(interes),
    original_deuda: new Intl.NumberFormat("de-DE").format(
      parseFloat(capital) + parseFloat(interes)
    ),

    original_interes_punitorio: real_interes_punitorio,
    //original_interes_punitorio: p_interes,

    //punitorios
    interes_punitorio: re_interes_punitorio,
    diferencia_moratoria: diferencia_dias,

    //cuota
    capital: re_capital,
    interes: re_interes,
    deuda: deuda,
    //excedente: JSON.stringify(excedente),

    //Pagos
    cantidad_pagos: cantidad_pagos,
    pagos: total_pagos,
    deuda_total: 0,
    pagos: array_pagos,

    //
    historia: history,
    cuota: cuota,
  };
};

export const corregir_bug = (detalles) => {
  /* Mapear cuotas */
  let cuota_sin_pagar = null;
  let cuota_con_deuda = false;
  let cuota_index = null;
  let resto = 0;

  let reacomodar_pagos = [];

  detalles.map((cuota, index) => {
    const result_cuota = calcular_cuota_corregir(cuota);
    /*Verificar si existe deuda*/
    console.log(result_cuota.deuda);
    if (parseFloat(result_cuota.deuda).toFixed(2) > 0) {
      //Existe...
      cuota_index = index + 1;
      cuota_con_deuda = result_cuota.deuda;
      cuota_sin_pagar = result_cuota;
      resto = parseFloat(cuota_con_deuda);

      //Buscar pago siguiente
      if (cuota_index < detalles.length) {
        //console.log(detalles[parseInt(cuota_index)]);

        //Existe pago siguiente
        if (detalles[parseInt(cuota_index)].pagos.length > 0) {
          for (
            let i = 0;
            i < detalles[parseInt(cuota_index)].pagos.length;
            i++
          ) {
            const pago = detalles[parseInt(cuota_index)].pagos[i];
            //Se resta la deuda de la anterior cuota con el primer pago de la siguiente
            if (resto > 0) {
              resto = resto - parseFloat(pago.importe);
            } else {
              //resto = 0;
            }

            //console.log("CUOTAA " + (index + 1));
            //console.log("RESTOO " + resto);

            if (parseFloat(resto).toFixed(2) < 0) {
              //Si es menor a 0 la cuota fue totalizada

              //reacomodo de primer pago en primer cuota
              reacomodar_pagos = [
                ...reacomodar_pagos,
                {
                  id: pago.id,
                  tipo: "pago_total_" + pago.id,

                  detalle_id: cuota_sin_pagar.cuota.id,
                  nro_cuota: cuota_index + 1,
                  fecha_importe: pago.fecha_importe,
                  id_concesion: pago.id_concesion,

                  estado: "TOTAL",
                  importe: parseFloat(pago.importe) + resto,
                  capital: 0,
                  interes: 0,
                  interes_punitorios: 0,
                  tipo_transaccion: "editar",
                },
              ];
              //Genero un excedente
              reacomodar_pagos = [
                ...reacomodar_pagos,
                {
                  id: pago.id,
                  tipo: "pago_original_" + pago.id,

                  detalle_id: detalles[parseInt(cuota_index)].id,
                  nro_cuota: cuota_index + 1,
                  fecha_importe: pago.fecha_importe,
                  id_concesion: pago.id_concesion,
                  credito_detalle_id: detalles[parseInt(cuota_index)].id,
                  id_concesion: pago.id_concesion,
                  credito_id: pago.credito_id,
                  forma_pago: pago.forma_pago,
                  comentarios: "",
                  sig_cuotas: "null",
                  //Credito
                  status_credito: "",

                  estado: "EXCEDENTE",
                  importe_cuota: -resto,
                  capital: 0,
                  interes: 0,
                  interes_punitorios: 0,
                  tipo_transaccion: "crear",
                },
              ];

              break;
            }

            if (resto > 0) {
              //Si la deuda es menor a 0 la cuota no fue totalizada y se resta del siguiente pago
              reacomodar_pagos = [
                ...reacomodar_pagos,
                {
                  id: pago.id,
                  tipo: "pago_parcial_" + pago.id,

                  fecha_importe: pago.fecha_importe,
                  detalle_id: cuota_sin_pagar.cuota.id,
                  nro_cuota: cuota_index,

                  estado: "PARCIAL",
                  importe: pago.importe,
                  capital: 0,
                  interes: 0,
                  interes_punitorios: 0,
                  tipo_transaccion: "editar",
                },
              ];
            }
          }
        }
      }
    } else {
    }
  });

  console.log(reacomodar_pagos);

  //Reacomodar pagos
  reacomodar_pagos.map((pago) => {
    if (pago.tipo_transaccion == "editar") {
      modificar(pago);
    }
    if (pago.tipo_transaccion == "crear") {
      agregar_pago(pago);
    }
  });
};

export const verificar_bug = (detalles) => {
  console.log(detalles);

  /* Mapear cuotas */
  let cuota_sin_pagar = false;
  let contiene_bug = false;

  detalles.map((cuota, index) => {
    const result_cuota = calcular_cuota_corregir(cuota);
    console.log(result_cuota);

    /* Si la cuota tiene pago total pero no se cancelo, se verifica que la siguiente cuota tenga pagos */
    if (cuota_sin_pagar) {
      if (result_cuota.pagos.length > 0) {
        /* Verificar cual pago corresponde volver atras */
        contiene_bug = true;
      }
    }

    if (parseFloat(result_cuota.deuda).toFixed(0) > 0) {
      cuota_sin_pagar = result_cuota;
    } else {
      cuota_sin_pagar = false;
    }
  });

  return contiene_bug;
};

/*
 if (cuota_sin_pagar) {
      if (result_cuota.pagos.length > 0) {
        let capital = cuota_sin_pagar.capital;
        let interes = cuota_sin_pagar.interes;
        let interes_punitorio = cuota_sin_pagar.interes_punitorio;

        result_cuota.pagos.map((pago) => {
          let importe = pago.importe;

          interes_punitorio =
            (interes_punitorio < 0 ? 0 : interes_punitorio) - importe;

          if (interes_punitorio < 0 && interes > 0) {
            interes = interes + interes_punitorio;
          } else {
            if (interes_punitorio < 0) {
              interes = interes_punitorio;
            }
          }

          if (interes < 0) {
            capital = capital + interes;
          }


          if (parseFloat(capital).toFixed(2) > 0) {
            console.log("Resto " + capital);
            console.log(pago);
            console.log(cuota_sin_pagar);

            let form_pago = {
              id: pago.id,

              detalle_id: cuota_sin_pagar.cuota.id,
              nro_cuota: cuota_sin_pagar_index + 1,

              estado: "PARCIAL",
              importe: pago.importe,
              capital: capital > 0 ? capital : 0,
              interes: interes > 0 ? interes : 0,
              interes_punitorios: interes_punitorio > 0 ? interes_punitorio : 0,
            };

            console.log("Pago parcial");
            console.log(form_pago);
            if(form_pago.importe > 0){
              re_pagos = {
                ...re_pagos,
                ["pago_parcial_"+pago.id]: form_pago,
              }
              //modificar(form_pago);
            }
          }

          if (parseFloat(capital).toFixed(2) == 0) {
            console.log("Resto " + capital);
            console.log(pago);
            console.log(cuota_sin_pagar);

            let form_pago = {
              id: pago.id,

              detalle_id: cuota_sin_pagar.cuota.id,
              nro_cuota: cuota_sin_pagar_index + 1,

              estado: "TOTAL",
              importe: cuota_sin_pagar.deuda,
              capital: capital > 0 ? capital : 0,
              interes: interes > 0 ? interes : 0,
              interes_punitorios: interes_punitorio > 0 ? interes_punitorio : 0,
            };

            console.log("Pago total");
            console.log(form_pago);

            if(parseFloat(form_pago.importe).toFixed(2) > 0){
              //modificar(form_pago);
              re_pagos = {
                ...re_pagos,
                ["pago_total_"+pago.id]: form_pago,
              }
            }
          }

          if (parseFloat(capital).toFixed(2) < 0) {
            console.log("Resto " + capital);
            console.log(pago);
            console.log(cuota_sin_pagar);

            let form_pago = {
              id: pago.id,

              detalle_id: cuota_sin_pagar.cuota.id,
              nro_cuota: cuota_sin_pagar_index + 1,

              estado: "TOTAL",
              importe: cuota_sin_pagar.deuda,
              capital: capital > 0 ? capital : 0,
              interes: interes > 0 ? interes : 0,
              interes_punitorios: interes_punitorio > 0 ? interes_punitorio : 0,
            };

            if(parseFloat(form_pago.importe).toFixed(2) > 0){
              //modificar(form_pago)
              re_pagos = {
                ...re_pagos,
                ["pago_total_"+pago.id]: form_pago,
              }
            }

            let exc_capital = result_cuota.capital;
            let exc_interes = result_cuota.interes;
            let exc_interes_punitorio = result_cuota.interes_punitorio;
            let exc_importe = - capital;

            exc_interes_punitorio =
              (exc_interes_punitorio < 0 ? 0 : exc_interes_punitorio) - exc_importe;

            if (exc_interes_punitorio < 0 && exc_interes > 0) {
              exc_interes = exc_interes + exc_interes_punitorio;
            } else {
              if (exc_interes_punitorio < 0) {
                exc_interes = exc_interes_punitorio;
              }
            }

            if (exc_interes < 0) {
              exc_capital = capital + exc_interes;
            }

            let form_pago_excedente = {
              importe_cuota: - capital,
              estado: "EXCEDENTE",
              prev_estado: "",
              forma_pago: pago.forma_pago,
              fecha_importe: pago.fecha_importe,
              credito_detalle_id: pago.credito_detalle_id,
              id_concesion: pago.id_concesion,
              credito_id: pago.credito_id,
              nro_cuota: index + 1,
              total_cuotas: "",
              comentarios: "",

              interes_punitorios: exc_interes_punitorio > 0 ? exc_interes_punitorio : 0 ,
              capital: exc_capital > 0 ? exc_capital : 0,
              interes: exc_interes > 0 ? exc_interes : 0,

              //Cuotas siguientes
              sig_cuotas: "null",
              //Credito
              status_credito: ""
            };


            if(parseFloat(form_pago_excedente.importe_cuota).toFixed(2) > 0){
              //agregar_pago(form_pago_excedente)
              re_pagos = {
                ...re_pagos,
                ["pago_excedente_"+pago.id]: form_pago,
              }
            }
          }
        });
      }
    }

    if (parseFloat(result_cuota.deuda).toFixed(0) > 0) {
      cuota_sin_pagar = result_cuota;
      cuota_sin_pagar_index = index;
    } else {
      cuota_sin_pagar = false;
      cuota_sin_pagar_index = null;
    } 
    */
