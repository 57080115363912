import { useHistory } from "react-router-dom/cjs/react-router-dom.min";
import { Permissions } from "../../../utils/permissions";

const HeaderComponent = ({
  path,
  pathname,
  cerrarSesion,
  usuario,
  notificaciones,
  Quitarnotificacion,
  onClickNotificacion,
  personas,
  search,
  onChange,
  onClick,
}) => {
  return (
    <div className="w-100 d-flex justify-content-between">
      <div className="d-none d-md-flex py-3">
        {path.map((p, index) => {
          if (p.dropdown && !Permissions(usuario.rol, p.path)) {
            return (
              <div class="dropdown">
                <button
                  className="border-0 shadown-sm fs-5 p-2 d-flex bg-light text-dark me-2"
                  style={{ borderRadius: "10px", textDecoration: "none" }}
                  ç
                  type="button"
                  data-bs-toggle="dropdown"
                  aria-expanded="false"
                >
                  {p.title}
                </button>
                <ul class="dropdown-menu">
                  {p.nav_dropdown.map((n, index) => (
                    <li>
                      <a class="dropdown-item" href="#">
                        {n.title}
                      </a>
                    </li>
                  ))}
                </ul>
              </div>
            );
          }

          if (!Permissions(usuario.rol, p.path)) {
            return (
              <a
                className={
                  pathname == p.path
                    ? "shadown-sm fs-6 btn d-flex bg-dark text-white me-2 align-items-center d-flex"
                    : "shadown-sm fs-6 btn d-flex bg-light text-dark me-2 align-items-center d-flex"
                }
                style={{ borderRadius: "20px", textDecoration: "none" }}
                href={p.path}
              >
                <span dangerouslySetInnerHTML={{ __html: p.icon }}></span>
                {p.title}
              </a>
            );
          }
        })}

        <div className="ms-3 d-md-flex d-none justify-content-center align-items-center">
          <div class="input-group" style={{ width: "500px" }}>
            <input
              type="text"
              class="form-control"
              style={{ borderRadius: "20px", width: "400px" }}
              placeholder="Busca por el nombre de cliente."
              aria-describedby="basic-addon2"
              autoComplete="off"
              name="buscar"
              onChange={(event) => {
                onChange(event);
              }}
            />
            <span
              class="input-group-text bg-transparent border-0"
              id="basic-addon1"
            >
              <i class="bi bi-search"></i>
            </span>

            {search.buscar.trim() !== "" ? (
              <ul
                class="list-group position-absolute w-100"
                style={{ top: "40px", zIndex: "2" }}
              >
                {personas.map((persona) => (
                  <li class="list-group-item w-100 p-0">
                    <div className="border-0 p-2 w-100">
                      <a
                        className="text-dark"
                        href={`/clientes/ver/${persona.id}`}
                      >
                        <span className="me-2">
                          {persona.nombre + " " + persona.apellido}
                        </span>
                        <span className="fw-bold">DNI: </span>
                        {persona.nro_documento}
                      </a>

                      <div className="d-block">
                        <ul class="list-group list-group-horizontal">
                          <li class="list-group-item p-0">
                            <a
                              className="text-dark btn btn-outline-dark py-0"
                              href={`/creditos/${persona.id}`}
                            >
                              Ver creditos{" "}
                              <i class="ms-1 bi bi-box-arrow-up-right"></i>
                            </a>
                          </li>
                          <li class="list-group-item p-0">
                          <a
                              className="text-dark btn btn-outline-dark py-0"
                              href={`/gestion-mora/reportes-atrasos/editar/${persona.id}`}
                            >
                              Ver procesos{" "}
                              <i class="ms-1 bi bi-box-arrow-up-right"></i>
                            </a>
                          </li>
                          <li class="list-group-item p-0">
                          <a
                              className="text-dark btn btn-outline-dark py-0"
                              href={`/gestion-mora/calendario/${persona.id}`}
                            >
                              Calendario{" "}
                              <i class="ms-1 bi bi-box-arrow-up-right"></i>
                            </a>
                          </li>
                        </ul>
                      </div>
                    </div>
                  </li>
                ))}
              </ul>
            ) : (
              ""
            )}
          </div>
        </div>
      </div>

      <div className="d-md-flex d-flex justify-content-center align-items-center">
        <label className="btn text-dark fs-4">{usuario.rol}</label>
        <div class="dropdown me-2">
          <button
            class="btn btn-light "
            style={{ width: "60px", height: "60px", borderRadius: "50%" }}
            data-bs-toggle="dropdown"
            type="button"
            aria-expanded="false"
          >
            <i class="bi bi-bell fs-3 text-dark"></i>
          </button>
          <ul class="dropdown-menu overflow-auto" style={{ height: "600px" }}>
            {notificaciones ? (
              <ul className="list-group bg-transparent shadow-0 border-0 ">
                {notificaciones.map((item) => (
                  <li
                    key={item.id}
                    className="list-group-item  border-0 shadow-0 mb-1 "
                    style={{ width: "300px" }}
                  >
                    <button
                      className="border-0 w-100 bg-white"
                      onClick={() => {
                        onClickNotificacion(item);
                      }}
                    >
                      <div className="border-0 py-1 text-start fw-bold">
                        {item.mensaje}
                      </div>
                      <div className="border-0 py-1 text-start">
                        {item.body}
                      </div>
                      <div className="text-end">Creado: {item.created}</div>
                    </button>
                  </li>
                ))}
              </ul>
            ) : null}
          </ul>
        </div>
        <div class="dropdown">
          <button
            class="btn btn-light "
            style={{ width: "60px", height: "60px", borderRadius: "50%" }}
            data-bs-toggle="dropdown"
            type="button"
            aria-expanded="false"
          >
            <i class="bi bi-person-fill fs-3 text-dark"></i>
          </button>
          <ul
            class="dropdown-menu border-0 shadown-sm bg-light"
            style={{ width: "400px", background: "none", borderRadius: "20px" }}
          >
            <li className="p-2 ">
              <label
                className="shadow-sm d-flex justify-content-between items-align-center bg-white px-3 pt-3 pb-1"
                style={{
                  borderRadius: "20px",
                }}
              >
                <div className="row">
                  <div className="col">
                    <div
                      className="bg-dark d-flex justify-content-center items-align-center"
                      style={{
                        borderRadius: "50%",
                        height: "50px",
                        width: "50px",
                      }}
                    >
                      <i class="bi bi-person-fill text-white fs-4 mt-1"></i>
                    </div>
                  </div>
                  <div className="col">
                    <p className="mb-1">{usuario.nombre}</p>
                    <p>{usuario.rol}</p>
                  </div>
                </div>
                <label
                  className="btn border"
                  style={{ borderRadius: "20px", height: "40px" }}
                >
                  Perfil
                </label>
                <button
                  className="d-none"
                  onClick={(e) => {
                    window.location.href = "/perfil";
                  }}
                ></button>
              </label>
            </li>
            <li className="p-2 ">
              <a
                class="dropdown-item d-flex align-items-center"
                href="#"
                onClick={() => {
                  cerrarSesion();
                  window.location.href = "/";
                }}
              >
                <i class="bi bi-power me-2 fs-3"></i>
                Cerrar sesion
              </a>
            </li>
          </ul>
        </div>
      </div>
    </div>
  );
};

export default HeaderComponent;
