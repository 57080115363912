import { format } from "date-fns";
import { FormatterNumber } from "../../../../../utils/formatter";
import { ApiRestPost } from "../../../../../Helpers/ApiRestPost";
import { useState } from "react";
import { useEffect } from "react";
import { Spinner } from "../../../../shared";

const TablaAtrasos = ({ data, onClick, onChange, sort, setSort }) => {
  const [loading, setLoading] = useState(false);
  const [seleccion, setSeleccion] = useState(null);
  const [seleccionSiguiente, setSeleccionSiguiente] = useState(null);

  useEffect(() => {
    const personaSeleccionada = localStorage.getItem("persona-seleccionada");
    if (personaSeleccionada) {
      setSeleccion(personaSeleccionada);
    }
  }, []);

  useEffect(() => {
    const personaSiguiente = localStorage.getItem("persona-siguiente");
    if (personaSiguiente) {
      setSeleccionSiguiente(personaSiguiente);
    }
  }, []);
  return (
    <table
      className="table table-hover align-middle overflow-scroll"
      style={{
        width: "200px",
      }}
    >
      <thead className="border-0 sticky">
        <tr
          className="border tr_sticky"
          style={{
            borderRadius: "20px",
            background: "rgba(217, 217, 217, 0.5)",
          }}
        >
          <th className="border-0 fs-5 p-1">
            <div
              className="border-0 p-1"
              style={{ borderRadius: "10px" }}
            ></div>
          </th>
          <th className="border-0 fs-5 p-1">
            <div className="border-0 p-1" style={{ borderRadius: "10px" }}>
              Nº
            </div>
          </th>
          <th className="border-0 fs-5 p-1">
            <label
              className="btn fs-5 fw-bold border-0 p-1 d-flex justify-content-between text-nowrap sort"
              style={{ borderRadius: "10px" }}
            >
              <span>Cliente</span>
              <div
                className={
                  sort == "-apellido" || sort == "apellido" ? "" : "sort-arrow"
                }
              >
                {sort == "apellido" ? (
                  <i class="bi bi-arrow-down"></i>
                ) : (
                  <i class="bi bi-arrow-up"></i>
                )}
                <button
                  className="d-none"
                  name="sort"
                  onClick={(event) =>
                    onClick(event, {
                      sort: sort == "-apellido" ? "apellido" : "-apellido",
                    })
                  }
                ></button>
              </div>
            </label>
          </th>
          <th className="border-0 fs-5 p-1">
            <div
              className="border-0 p-1 text-nowrap"
              style={{ borderRadius: "10px" }}
            >
              Telefono
            </div>
          </th>
          <th className="border-0 fs-5 p-1">
            <label
              className="btn fs-5 fw-bold border-0 p-1 d-flex justify-content-between text-nowrap sort"
              style={{ borderRadius: "10px" }}
            >
              <span>Condición</span>
              <div
                className={
                  sort == "-condicion" || sort == "condicion"
                    ? ""
                    : "sort-arrow"
                }
              >
                {sort == "condicion" ? (
                  <i class="bi bi-arrow-down"></i>
                ) : (
                  <i class="bi bi-arrow-up"></i>
                )}
                <button
                  className="d-none"
                  name="sort"
                  onClick={(event) =>
                    onClick(event, {
                      sort: sort == "-condicion" ? "condicion" : "-condicion",
                    })
                  }
                ></button>
              </div>
            </label>
          </th>
          <th className="border-0 fs-5 p-1">
            <div
              className="border-0 p-1 text-nowrap"
              style={{ borderRadius: "10px" }}
            >
              Créditos al día
            </div>
          </th>
          <th className="border-0 fs-5 p-1">
            <label
              className="btn border-0 p-1 fs-5 fw-bold text-nowrap d-flex justify-content-between sort"
              style={{ borderRadius: "10px" }}
            >
              <span>Créditos atrasados</span>
              <div
                className={
                  sort == "-cuotas_atrasadas_count" ||
                  sort == "cuotas_atrasadas_count"
                    ? ""
                    : "sort-arrow"
                }
              >
                {sort == "cuotas_atrasadas_count" ? (
                  <i class="bi bi-arrow-down"></i>
                ) : (
                  <i class="bi bi-arrow-up"></i>
                )}
                <button
                  className="d-none"
                  name="sort"
                  onClick={(event) =>
                    onClick(event, {
                      sort:
                        sort == "-cuotas_atrasadas_count"
                          ? "cuotas_atrasadas_count"
                          : "-cuotas_atrasadas_count",
                    })
                  }
                ></button>
              </div>
            </label>
          </th>
          <th className="border-0 fs-5 p-1">
            <label
              className="btn fs-5 fw-bold border-0 p-1 d-flex justify-content-between text-nowrap sort"
              style={{ borderRadius: "10px" }}
            >
              <span>Fecha ultimo pago</span>
              <div
                className={
                  sort == "-ultimo_pago.created_at" ||
                  sort == "ultimo_pago.created_at"
                    ? ""
                    : "sort-arrow"
                }
              >
                {sort == "ultimo_pago.created_at" ? (
                  <i class="bi bi-arrow-down"></i>
                ) : (
                  <i class="bi bi-arrow-up"></i>
                )}
                <button
                  className="d-none"
                  name="sort"
                  onClick={(event) =>
                    onClick(event, {
                      sort:
                        sort == "-ultimo_pago.created_at"
                          ? "ultimo_pago.created_at"
                          : "-ultimo_pago.created_at",
                    })
                  }
                ></button>
              </div>
            </label>
          </th>
          <th className="border-0 fs-5 p-1">
            <div
              className="border-0 p-1 text-nowrap"
              style={{ borderRadius: "10px" }}
            >
              Deuda acumulada
            </div>
          </th>
          <th className="border-0 fs-5 p-1">
            <div
              className="border-0 p-1 text-nowrap"
              style={{ borderRadius: "10px" }}
            >
              Prescripción
            </div>
          </th>
          <th className="border-0 fs-5 p-1">
            <div className="border-0 p-1" style={{ borderRadius: "10px" }}>
              Procesos
            </div>
          </th>
        </tr>
      </thead>
      {!data ? (
        <td className="my-0 border-0 p-0" colSpan={10}>
          <Spinner />
        </td>
      ) : (
        <tbody className="border-0">
          {data.map((p, index) => (
            <tr
              className={`border border-end-0 border-start-0`}
              style={{
                background:
                  seleccion == `${p.apellido} ${p.nombre}`
                    ? "#67a9ff"
                    : seleccionSiguiente == `${p.apellido} ${p.nombre}`
                    ? "#91f98c"
                    : "",
              }}
              onClick={() => {
                setSeleccion(`${p.apellido} ${p.nombre}`);
                setSeleccionSiguiente(
                  `${data[index + 1].apellido} ${data[index + 1].nombre}`
                );
                localStorage.setItem(
                  "persona-seleccionada",
                  `${p.apellido} ${p.nombre}`
                );
                localStorage.setItem(
                  "persona-siguiente",
                  `${data[index + 1].apellido} ${data[index + 1].nombre}`
                );
              }}
            >
              <td>
                <label className="btn btn-primary fs-6 d-flex me-3 text-nowrap div-crear-proceso">
                  <i class="bi bi-plus-lg me-2"></i>
                  <span className="span-crear-proceso">Iniciar proceso</span>
                  <button
                    data-bs-toggle="modal"
                    data-bs-target="#crear-gasto"
                    className="d-none"
                    onClick={async () => {
                      setSeleccion(`${p.apellido} ${p.nombre}`);
                      setSeleccionSiguiente(
                        `${data[index + 1].apellido} ${data[index + 1].nombre}`
                      );
                      localStorage.setItem(
                        "persona-seleccionada",
                        `${p.apellido} ${p.nombre}`
                      );
                      localStorage.setItem(
                        "persona-siguiente",
                        `${data[index + 1].apellido} ${data[index + 1].nombre}`
                      );

                      let formData = new FormData();
                      formData.append("persona_id", p.id);
                      const response = await ApiRestPost({
                        url: "/personas/" + p.id + "/procesos",
                        setLoading: setLoading,
                        form: formData,
                      });

                      return (
                        (window.location.href =
                          "/gestion-mora/reportes-atrasos/editar/" + p.id),
                        "_blank"
                      );
                    }}
                  ></button>
                </label>
              </td>
              <td className="border-0 text-nowrap">{p.id}</td>
              <td className="border-0 text-nowrap">
                <a
                  className="text-dark"
                  href={`/clientes/ver/${p.id}`}
                  target="_blank"
                >
                  {p.apellido + " " + p.nombre}
                </a>
              </td>
              <td className="border-0 text-nowrap">
                {p.contactos.length == 0
                  ? ""
                  : p.contactos.map((c) => (
                      <p>{c.nro_telefono_prefix + "-" + c.nro_telefono}</p>
                    ))}
              </td>
              <td className="border-0">{p.condiciones?.condicion}</td>
              <td className="border-0">
                {p.deudaAcumulada.map((credito) => {
                  if (credito.deuda == 0) {
                    return (
                      <a
                        className="d-block "
                        href={`/creditos/ver/${credito.credito_id}`}
                        target="_blank"
                      >
                        {credito.credito_id}
                      </a>
                    );
                  }
                })}
              </td>
              <td className="border-0">
                {p.deudaAcumulada.map((credito) => {
                  if (credito.deuda > 0) {
                    return (
                      <a
                        className="d-block "
                        href={`/creditos/ver/${credito.credito_id}`}
                        target="_blank"
                      >
                        {credito.credito_id}
                      </a>
                    );
                  }
                })}
              </td>
              <td className="border-0">
                {p.ultimoPago.map((p) => (
                  <p className="d-block ">
                    {format(new Date(p.created_at), "dd/MM/yyyy").toString()}
                  </p>
                ))}
              </td>
              <td className="border-0">
                $
                {new Intl.NumberFormat("de-DE").format(
                  p.deudaAcumulada.reduce(
                    (acc, credito) => acc + credito.deuda,
                    0
                  )
                )}
              </td>
              <td className="border-0">
                {p.prescripcion.map((p) => (
                  <p className="d-block text-nowrap" href="#">
                    {p.id_credito +
                      " - " +
                      format(
                        new Date(p.fecha_prescripcion),
                        "dd/MM/yyyy"
                      ).toString()}
                  </p>
                ))}
              </td>
              <td className="border-0">
                {p.procesos.map((p) => (
                  <a
                    className="d-block text-nowrap"
                    href={
                      "/gestion-mora/reportes-atrasos/editar/" +
                      p.persona_id +
                      "/" +
                      p.id
                    }
                  >
                    {p.id + " " + p.estado}
                  </a>
                ))}
              </td>
            </tr>
          ))}
        </tbody>
      )}
      <tfoot></tfoot>
    </table>
  );
};

export default TablaAtrasos;
