import { format, add, sub } from "date-fns";

const cancelarConcesion = () => {
  return "";
};

const dateExtra = () => {
  return format(add(new Date(), {days: 1}), 'yyyy-MM-dd').toString()
}

export const CreditoDetalles = (credito) => {
  const { detalles, concesion } = credito;
  //fecha de vto concesion
  let fecha_vto = concesion
      ? concesion.inicio_vencimiento.toString() === "0000-00-00"
      ? new Date(dateExtra()+" 00:00:00")
      : new Date(concesion.inicio_vencimiento)
      : null;
  let concesion_fecha_vencimiento = concesion
      ? new Date(concesion.inicio_vencimiento)
      : null;
  let concesion_fecha_alta = concesion
      ? format(new Date(concesion.fecha_alta), "yyyy/MM/dd").toString()
      : null;
  //Datos extras
  let idUltimoPago = null;
  let today = new Date();

  let nro = 0;
  

  detalles.map((cuota, index) => {
    cuota.bonificacion_concesion = null;
    cuota.fecha_alta_concesion = null;
    cuota.fecha_vencimiento_concesion = null; //initialState vto concesion
    let state = false;

    if (fecha_vto < today) {
      state = false;
    } else {
      state = true;
    }

    //validacion de pagos totales
    cuota.pagos.map((pago, index) => {
      if (pago.estado == "TOTAL") {
        let fecha_importe = format(
          new Date(pago.fecha_importe + " 00:00:00"),
          "yyyy/MM/dd"
        ).toString();

        //Aplicar concesion despues de pago total de cuota
        if (new Date(fecha_importe) >= new Date(concesion_fecha_alta)) {
          //Cancelar concesion si vence nueva fecha de pago
          if (new Date(fecha_importe) >= fecha_vto) {
            state = false;
          } else {
            state = true;
          }
        } else {
          state = false;
        }
      }
      idUltimoPago = pago.id;
    });

    //concesion activa
    if (concesion && state) {
      //Cancelar concesion
      /*
      if (fecha_vto < today) {
        state = false;
      } else {
        state = true;
      }
      */

      //concesion por congelamiento de IP
      if (
        parseInt(concesion.id_tipoconcesion) == 1 ||
        parseInt(concesion.id_tipoconcesion) == 3
      ) {
        //Congelamiento de IP
        cuota.fecha_alta_concesion = concesion_fecha_alta;

        //Nuevas fechas de vencimiento
        cuota.fecha_vencimiento_concesion =
          nro == 0
            ? format(add(fecha_vto, { days: 1 }), "yyyy/MM/dd").toString()
            : format(add(fecha_vto, { months: 1 }), "yyyy/MM/dd").toString();

        //GUARD
        fecha_vto = new Date(cuota.fecha_vencimiento_concesion + " 00:00:00");
        nro = nro + 1;
      }
      //concesion por bonificacion y corre IP
      if (
        parseInt(concesion.id_tipoconcesion) == 2 ||
        parseInt(concesion.id_tipoconcesion) == 3
      ) {
        //bonificacion
        cuota.bonificacion_concesion = concesion.porcentaje;
      }

      //
      concesion.id_estado = 1;
    } else {
      if (concesion && credito.estado != "Pagado") {
        concesion.id_estado = 2;
      }
    }
  });

  credito.id_ultimo_pago = idUltimoPago;

  return credito;
};
