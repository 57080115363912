import { useState } from "react";
import { useEffect } from "react";
import { ApiCreditos } from "../../Helpers/ApiCreditos";
import Resumen from "./Modals/Resumen";
import Panel from "./Panel/panel";
import Tabla_creditos from "./Tablas/Tabla_creditos";
import Swal from "sweetalert2";
import { AuthContext, clienteAxios } from "../shared";
import Caja from "./Modals/Caja";
import { ApiPagos } from "../../Helpers/ApiPagos";
import { ApiPersonas } from "../../Helpers/ApiPersonas";
import { CuotaPago } from "../../functions/Validaciones/CuotaPago";
import { CreditoDetalles } from "../../functions/Validaciones/CreditoDetalles";
import {
  CorregirBug,
  PagarCuota,
  corregir_bug,
} from "../../functions/FunctionCuota";
import { CuotasDetalle } from "../../functions/Validaciones/CuotasDetalle";
import Comprobante from "./Modals/Comprobante";
import { useContext } from "react";
import { format } from "date-fns";
import AlertSuccess from "../CustomAlerts/AlertSuccess";
import Tabla_judiciales from "./Tablas/Tabla_judiciales";
import { ApiJudiciales } from "../../Helpers/ApiJudiciales";
import Gasto from "./Modals/Gasto";
import Juicio from "./Modals/Juicio";
import ResumenJudicial from "./Modals/ResumenJudicial";
import PanelJudicial from "./Panel/panelJudicial";
import { CalculoPagoJudicial } from "../../functions/Validaciones/PagoJudicial";
import AlertWarning from "../CustomAlerts/AlertWarning";
import Modal from "../../module/modal/Modal";
import ComprobanteGastoController from "./Modals/CompobanteGasto";

const GestionPagos = () => {
  const authContext = useContext(AuthContext);
  const { usuario, cerrarSesion } = authContext;

  const autocomplete = {
    top: "40px",
    zIndex: "2",
  };
  //modals
  const [comprobante, setComprobante] = useState(false);
  const [comprobanteGasto, setComprobanteGasto] = useState(false);
  const [PagoJudicial, setPagoJudicial] = useState(false);
  const [caja, setCaja] = useState(false);
  const [resumen, setResumen] = useState(false);
  const [gasto, setGasto] = useState(false);
  const [resumenJudicial, setResumenJudicial] = useState(false);
  //
  const [panelJuicio, setPanelJuicio] = useState(false);
  const [pago, setPago] = useState(false);
  const [steps, setSteps] = useState(0);
  const [loading, setLoading] = useState(false);
  const [judiciales, setJudiciales] = useState(null);
  const [juicio, setJuicio] = useState(null);

  //formularios
  const [form, setForm] = useState({
    //PAGO
    importe_cuota: "",
    importe: "",
    estado: "",
    prev_estado: "",
    forma_pago: "",
    fecha_importe: "",
    comentarios: "",
    //CREDITO
    credito_id: "",
    credito_detalle_id: "",
    id_concesion: "",
    monto_concesion: "",
    interes_punitorios: "",
    //Cuota siguiente
    sig_cuotas: "",
  });

  //params
  const [params_pago, setParamsPago] = useState({
    id: "",
    buscar: "",
  });
  const [params_persona, setParamsPersona] = useState({
    buscar: "",
    page: 1,
    paginator: 30,
  });
  const [params_credito, setParamsCredito] = useState({
    persona: null,
  });
  const [params_judicial, setParamsJudicial] = useState({
    persona: null,
  });

  //state
  const [dataPagos, setPagos] = useState([]);
  const [dataPersonas, setPersonas] = useState([]);
  const [dataCreditos, setCreditos] = useState([]);
  const [credito, setCredito] = useState(null);
  const [cuota, setCuota] = useState(null);

  //events
  const onClick = (e, value) => {
    switch (e.target.name) {
      case "delete-gasto":
        {
          if (value.pagos.length > 0) {
            return AlertWarning({
              message:
                "El gasto contiene pagos habilitados, no se puede eliminar",
              title: "Danger",
            });
          }
          //LLave de administrador
          Swal.fire({
            title: "Cancelar pago",
            html:
              "<p>¿Quieres dar de baja el siguiente gasto?</p>" +
              '<ul class="list-group"><li class="list-group-item border-0 d-flex justify-content-between"><div>Estado</div><div>' +
              value.item +
              '</div></li><li class="list-group-item border-0 d-flex justify-content-between"><div>Fecha de importe</div><div>' +
              value.fecha_otorgamiento +
              '</div></li><li class="list-group-item border-0 d-flex justify-content-between"><div>Tipo de importe</div><div>' +
              value.monto +
              '</ul><div class="my-3 text-muted"> Para confirmar la baja de un pago se necesitan credenciales de administrador, ingrese su clave para completar la baja.</div>',
            input: "password",
            inputAttributes: {
              autocapitalize: "off",
            },
            showCancelButton: true,
            cancelButtonText: "Cancelar",
            confirmButtonText: "Eliminar",
            showLoaderOnConfirm: true,
            preConfirm: async (result) => {
              let formData = new FormData();
              formData.append("id", value.id);
              formData.append("password", result);

              const response = await clienteAxios({
                method: "post",
                url: "/PersonaJudicial/delete",
                data: formData,
              })
                .then((result) => {
                  Swal.fire({
                    title: `Se dio de Baja Correctamente`,
                  });

                  //Actualizar datos
                  setSteps(steps + 1);
                })
                .catch((result) => {
                  return Swal.showValidationMessage(
                    `Request failed: ${result.response.data.messages.msg}`
                  );
                });
            },
            allowOutsideClick: () => !Swal.isLoading(),
          }).then((result) => {
            console.log("ok");
          });
        }
        break;
      case "resumen-judicial":
        {
          setJuicio({
            ...value,
            exmonto: CalculoPagoJudicial(value).exmonto,
            deuda: CalculoPagoJudicial(value).deuda,
            interes: CalculoPagoJudicial(value).interes,
          });
          setResumenJudicial(true);
        }
        break;
      case "crear-gasto":
        {
          setGasto(true);
        }
        break;
      //C-bug
      case "reacomodar-pagos":
        {
          corregir_bug(value);

          AlertSuccess({ message: "Correccion de bug completada..." });
          setResumen(false);
          //return window.location.reload(true)
        }
        break;
      //actions
      case "delete_payment":
        {
          //LLave de administrador
          Swal.fire({
            title: "Cancelar pago",
            html:
              "<p>¿Quieres dar de baja el siguiente pago?</p>" +
              '<ul class="list-group"><li class="list-group-item border-0 d-flex justify-content-between"><div>Estado</div><div>' +
              value.estado +
              '</div></li><li class="list-group-item border-0 d-flex justify-content-between"><div>Fecha de importe</div><div>' +
              value.fecha_importe +
              '</div></li><li class="list-group-item border-0 d-flex justify-content-between"><div>Tipo de importe</div><div>' +
              value.forma_pago +
              '</div></li><li class="list-group-item border-0 d-flex justify-content-between"><div>Importe</div><div>' +
              value.importe +
              '</div></li></ul><div class="my-3 text-muted"> Para confirmar la baja de un pago se necesitan credenciales de administrador, ingrese su clave para completar la baja.</div>',
            input: "password",
            inputAttributes: {
              autocapitalize: "off",
            },
            showCancelButton: true,
            cancelButtonText: "Cancelar",
            confirmButtonText: "Eliminar",
            showLoaderOnConfirm: true,
            preConfirm: async (result) => {
              let formData = new FormData();
              formData.append("id", value.id);
              formData.append("password", result);

              const response = await clienteAxios({
                method: "post",
                url: "/Pago/darBaja",
                data: formData,
              })
                .then((result) => {
                  Swal.fire({
                    title: `Se dio de Baja Correctamente`,
                  });

                  //Actualizar datos
                  setResumen(false);
                  setSteps(steps + 1);
                })
                .catch((result) => {
                  return Swal.showValidationMessage(
                    `Request failed: ${result.response.data.messages.msg}`
                  );
                });
            },
            allowOutsideClick: () => !Swal.isLoading(),
          }).then((result) => {
            console.log("ok");
          });
        }
        break;
      case "pagar-juidicial":
        {
          if (CalculoPagoJudicial(value).deuda < 1) {
            return "";
          }
          setPanelJuicio(true);
          setJuicio({
            ...value,
            exmonto: CalculoPagoJudicial(value).exmonto,
            deuda: CalculoPagoJudicial(value).deuda,
            interes: CalculoPagoJudicial(value).interes,
          });
          setCuota(null);
          setCredito(null);
        }
        break;
      case "persona":
        {
          setParamsCredito({
            ...params_credito,
            [e.target.name]: value,
          });

          //reset
          setParamsPersona({
            ...params_persona,
            buscar: "",
          });

          setParamsJudicial({
            ...params_judicial,
            persona: value,
          });

          setJudiciales(null);
          setCuota(null);
          setCredito(null);
        }
        break;
      case "credito":
        {
          let credito = () => CreditoDetalles(value);
          let cuota = () => PagarCuota(CreditoDetalles(value).detalles);
          setCuota(cuota);
          setCredito(credito);
          setJuicio(null);

          //reseteo de datos
          setForm({
            //PAGO
            importe: "",
            estado: "",
            forma_pago: "",
            fecha_importe: "",
            comentarios: "",
            //CREDITO
            credito_id: "",
            credito_detalle_id: "",
            id_concesion: "",
            monto_concesion: "",
            interes_punitorios: "",
            //
          });
        }
        break;
      //modals
      case "caja":
        {
          setCaja(value);
        }
        break;
      case "resumen":
        {
          let cuota = () => PagarCuota(value.detalles);
          let credito = () => CreditoDetalles(value);
          setCuota(cuota);
          setCredito(credito);
          setResumen(true);

          /*scroll div*/
          return (window.location.href = "#modal-resumen");
        }
        break;
      case "close":
        {
          setResumenJudicial(null);
          setResumen(false);
          setCaja(false);
          setComprobante(false);
          setPago(null);
          setJuicio(null);
          setComprobanteGasto(false)
        }
        break;
    }
  };

  const onChange = (e) => {
    switch (e.target.name) {
      case "buscar":
        {
          if (e.target.value.length > 3) {
            setParamsPersona({
              ...params_persona,
              [e.target.name]: e.target.value,
              page: 1,
              paginator: 30,
            });
          } else {
            if (e.target.value.length == "") {
              setParamsPersona({
                ...params_persona,
                [e.target.name]: "",
                page: 1,
                paginator: 30,
              });
            }
          }
        }
        break;
    }
  };
  //personas
  useEffect(() => {
    const load = async () => {
      const response = await clienteAxios.get("/Persona/getAll", {
        params: { texto: params_persona.buscar, page: 1, paginator: 30 },
      });

      setPersonas(response.data.personas.personas);
    };
    load();
  }, [params_persona, steps]);
  //creditos
  useEffect(() => {
    const load = async () => {
      if (params_credito.persona) {
        const response = await ApiCreditos({
          url: "/PersonaCreditos/getAllByIdPersona",
          params: params_credito,
          setLoading: setLoading,
        });

        setCreditos(response);
      }
    };
    load();
  }, [params_credito, steps]);
  //Pagos
  useEffect(() => {
    const load = async () => {
      const response = await ApiPagos({
        url: "/Pago/getAll",
        params: params_pago,
        setLoading: setLoading,
      });

      setPagos(response);
    };
    load();
  }, [params_pago, steps]);
  //judiciales
  useEffect(() => {
    const load = async () => {
      if (params_judicial.persona) {
        const response = await ApiJudiciales({
          url: "/PersonaJudicial/index",
          params: params_judicial,
          setLoading: setLoading,
        });

        setJudiciales(response);
      }
    };
    if (true) {
      load();
    }
  }, [params_judicial, steps]);

  //Logs
  //console.log(comprobante);
  return (
    <div className="container-fluid min-vh-100" style={{ maxWidth: "2500px" }}>
      <div className="p-4 d-flex justify-content-between align-items-center">
        <h3 className="text-nowrap me-3 fw-bold">GESTION DE PAGOS</h3>
        <div class="input-group" style={{ width: "500px" }}>
          <input
            type="text"
            class="form-control"
            style={{ borderRadius: "20px", width: "400px" }}
            placeholder="Busca el credito utilizando el nombre del cliente."
            aria-describedby="basic-addon2"
            autoComplete="off"
            name="buscar"
            onChange={(event) => onChange(event)}
          />
          <span
            class="input-group-text bg-transparent border-0"
            id="basic-addon1"
          >
            <i class="bi bi-search"></i>
          </span>

          {params_persona.buscar.trim() !== "" ? (
            <ul class="list-group position-absolute w-100" style={autocomplete}>
              {dataPersonas.map((persona) => (
                <li class="list-group-item w-100 p-0">
                  <label className="border-0  btn btn-outline-dark w-100 d-flex justify-content-start">
                    <div className="me-2">
                      {persona.nombre + " " + persona.apellido}
                    </div>
                    <div className="">
                      <span className="fw-bold">DNI: </span>
                      {persona.nro_documento}
                    </div>
                    <button
                      title="Seleccionar persona"
                      className="d-none"
                      name="persona"
                      onClick={(event) => onClick(event, persona)}
                    ></button>
                  </label>
                </li>
              ))}
            </ul>
          ) : (
            ""
          )}
        </div>

        {params_credito.persona ? (
          <div
            className="col-md-3 col-12 mb-3 d-flex justify-content-center bg-light px-2"
            style={{ borderRadius: "20px" }}
          >
            <div className="d-flex align-items-center ">
              <i class="bi bi-person-circle" style={{ fontSize: "100px" }}></i>
            </div>
            <ul
              class="list-group w-100 p-2 bg-transparent"
              style={{ borderRadius: "20px" }}
            >
              <li
                class="list-group-item border-0 d-flex justify-content-between bg-transparent"
                style={{
                  borderRadius: "20px 20px 0px 0px",
                }}
              >
                <div className="text-dark fw-bold fs-5">ID</div>
                <span className="fw-boldm ms-2 fs-5">
                  {params_credito.persona.id}
                </span>
              </li>
              <li class="list-group-item border-0 d-flex justify-content-between bg-transparent">
                <div className="text-dark fw-bold fs-5">Nombre</div>
                <div className="fw-bold fs-5">
                  {params_credito.persona.nombre +
                    " " +
                    params_credito.persona.apellido}
                </div>
              </li>
              <li
                class="list-group-item border-0 d-flex justify-content-between bg-transparent"
                style={{
                  borderRadius: "0px 0px 20px 20px",
                }}
              >
                <div className="fw-bold fs-5">DNI</div>
                <div className="fw-bold fs-5">
                  {params_credito.persona.nro_documento}
                </div>
              </li>
            </ul>
          </div>
        ) : (
          ""
        )}
      </div>

      <div className="row">
        <div
          className="col-md-12 mb-3 bg-light p-3"
          style={{
            borderRadius: "20px 20px 20px 20px",
          }}
        >
          <div
            className="fw-bold fs-3 mb-1 text-center w-100"
            style={{
              borderRadius: "20px 20px 20px 20px",
            }}
          >
            CREDITOS
          </div>

          {dataCreditos.length !== 0 ? (
            <Tabla_creditos
              data={dataCreditos}
              onClick={onClick}
              onChange={onChange}
            />
          ) : (
            <div className="d-flex justify-content-center border py-5">
              <span className="fs-4 text-muted">
                Selecciona una persona para visualizar sus creditos
              </span>
            </div>
          )}
        </div>

        <div
          className="col-md-12 mb-3 bg-light p-3"
          style={{
            borderRadius: "20px 20px 20px 20px",
          }}
        >
          <div
            className="fw-bold fs-3 mb-1 text-center w-100 py-2"
            style={{
              borderRadius: "20px 20px 20px 20px",
            }}
          >
            GASTOS JUDICIALES
          </div>

          {judiciales ? (
            <Tabla_judiciales
              data={judiciales}
              onClick={onClick}
              onChange={onChange}
            />
          ) : (
            <div className="d-flex justify-content-center border py-5">
              <span className="fs-4 text-muted">
                Selecciona una persona para visualizar sus gastos judificales
              </span>
            </div>
          )}
        </div>

        <div className="col-md-12">
          {panelJuicio && juicio ? (
            <PanelJudicial
              juicio={juicio}
              setJuicio={setJuicio}
              setCredito={setCredito}
              setCuota={setCuota}
              //pasos marcados
              setSteps={setSteps}
              steps={steps}
              setPago={setPagoJudicial}
              setComprobante={setComprobanteGasto}
            />
          ) : (
            ""
          )}
          {credito && cuota ? (
            <Panel
              credito={credito}
              cuota={cuota}
              setCredito={setCredito}
              setCuota={setCuota}
              //pasos marcados
              persona={params_credito.persona}
              setSteps={setSteps}
              steps={steps}
              pago={pago}
              setPago={setPago}
              //setComprobante={setComprobante}
              //formulario
              form={form}
              setForm={setForm}
            />
          ) : (
            <div className="d-flex justify-content-center border py-5">
              <span className="fs-4 text-muted">
                Selecciona un credito para ingresar un pago
              </span>
            </div>
          )}
        </div>
      </div>

      <Modal
        title="Resumen de pagos"
        id="resumen-credito"
        width={"1500px"}
        onClose={{
          name: "close",
          function: onClick,
        }}
        children={
          resumen && credito ? (
            <Resumen
              data={credito}
              setPago={setPago}
              pago={pago}
              setComprobante={setComprobante}
              usuario={usuario}
              onClick={onClick}
              onChange={onChange}
              setSteps={setSteps}
              steps={steps}
              onClose={onClick}
              persona={params_credito.persona}
            />
          ) : (
            ""
          )
        }
      />

      {/*comprobante ? (
        <Comprobante
          data={pago}
          persona={params_credito.persona}
          onClick={onClick}
        />
      ) : (
        ""
      )*/}

      {comprobanteGasto ? (
        <ComprobanteGastoController
          data={PagoJudicial}
          persona={params_credito.persona}
          onClick={onClick}
        />
      ) : (
        ""
      )}

     

      <Modal
        title="Resumen de pagos"
        id="resumen-gastos"
        width={"1500px"}
        steps={steps}
        setSteps={setSteps}
        onClose={{
          name: "close",
          function: onClick,
        }}
        children={
          resumenJudicial ? (
            <ResumenJudicial
              data={params_credito.persona}
              juicio={juicio}
              setModal={setResumenJudicial}
              setSteps={setSteps}
              steps={steps}
              onClose={onClick}
              //comprobante={comprobante}
              //setComprobante={setComprobante}
              setPago={setPago}
              pago={pago}
            />
          ) : (
            ""
          )
        }
      />

      {gasto ? (
        <Juicio
          data={params_credito.persona}
          setModal={setGasto}
          setSteps={setSteps}
          usuario={usuario}
        />
      ) : (
        ""
      )}
    </div>
  );
};

export default GestionPagos;
