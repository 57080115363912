import React, { useState, useEffect, useContext } from "react";
import ListarObservaciones from "./ListarObservaciones";
import { Spinner, clienteAxios, Portal, AuthContext } from "../../shared/";
import { BotonAgregar } from "../../shared/Estilos";
import FormObservacion from "./FormObservacion";
import Modal from "../../../module/modal/Modal";
import ListarCondiciones from "./ListarCondiciones";
import apiAxios from "../../../config/ApiAxios";
import FormCondicion from "./FormCondicion";

const Observaciones = ({ id, soloLectura }) => {
  const authContext = useContext(AuthContext);
  const { usuario } = authContext;
  const [loadingLocal, setLoadingLocal] = useState(null);
  const [lista, setLista] = useState(null);
  const [condiciones, setCondiciones] = useState(null);
  const [consultar, setConsultar] = useState(true);
  const [observacion, setObservacion] = useState(null);
  const [condicion, setCondicion] = useState(null);

  const fetchObservaciones = async () => {
    const result = await clienteAxios.get("/personasobservacion", {
      params: { id },
    });
    setLista(result.data.observaciones);
  };

  const fetchCondiciones = async () => {
    const result = await apiAxios.get("/condiciones", {
      params: { persona_id: id },
    });
    setCondiciones(result.data);
  };

  useEffect(() => {
    if (!lista) {
      fetchObservaciones();
    }
  }, []);

  useEffect(() => {
    if (!condiciones) {
      fetchCondiciones();
    }
  }, []);

  const onClick = (e) => {
    switch (e.target.name) {
      case "close":
        {
          setObservacion(null);
          setCondicion(null);
        }
        break;
      default:
        break;
    }
  };
  return (
    <div className="center-block">
      <br></br>

      <div className="row">
        {!soloLectura ? (
          <div className="col-sm d-flex">
            <div className="form-group">
              <BotonAgregar
                data-bs-toggle="modal"
                data-bs-target="#crear-observacion"
                className="btn btn-dark me-2"
              >
                <i className="fa fa-plus-circle"></i> Agregar Observacion
              </BotonAgregar>
            </div>
          </div>
        ) : null}
        <br></br>
      </div>

      <br></br>
      {loadingLocal || !lista ? (
        <Spinner />
      ) : (
        <ListarObservaciones
          lista={lista}
          setSeleccionado={setObservacion}
          soloLectura={soloLectura}
          fetchObservaciones={fetchObservaciones}
        />
      )}

      <div className="row">
        {!soloLectura ? (
          <div className="col-sm d-flex">
            <div className="form-group">
              <BotonAgregar
                data-bs-toggle="modal"
                data-bs-target="#crear-condicion"
                className="btn btn-dark"
              >
                <i className="fa fa-plus-circle"></i> Agregar Condición
              </BotonAgregar>
            </div>
          </div>
        ) : null}
        <br></br>
      </div>

      <br></br>
      {loadingLocal || !condiciones ? (
        <Spinner />
      ) : (
        <ListarCondiciones
          lista={condiciones}
          setSeleccionado={setCondicion}
          soloLectura={soloLectura}
          fetch={fetchCondiciones}
        />
      )}

      <Modal
        title={"Nueva observacion"}
        id="crear-observacion"
        width={"900px"}
        onClose={{
          id: "btn-close-modal-observacion",
          name: "close",
          function: onClick,
        }}
        onClick={null}
        children={
          <FormObservacion
            idpersona={id}
            Seleccionado={observacion}
            setObservacion={setObservacion}
            fetchObservaciones={fetchObservaciones}
          />
        }
      />

      <Modal
        title={"Nueva condicion"}
        id="crear-condicion"
        width={"900px"}
        onClose={{
          id: "btn-close-modal-condicion",
          name: "close",
          function: onClick,
        }}
        onClick={null}
        children={
          <FormCondicion
            idpersona={id}
            Seleccionado={condicion}
            setCondicion={setCondicion}
            fetch={fetchCondiciones}
            usuario={usuario}
          />
        }
      />
    </div>
  );
};

export default Observaciones;
