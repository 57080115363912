import React, { useState, useContext, useEffect } from "react";
import {
  Input,
  BotoneraForm,
  Select,
  BotonSubmit,
} from "../../shared/FormsElements";
import {
  Spinner,
  useAlerta,
  PersonasContext,
  clienteAxios,
  useLog,
} from "../../shared/";
import { ApiRestPost } from "../../../Helpers/ApiRestPost";

const FormCondicion = ({
  idpersona,
  closeModal,
  Seleccionado,
  usuario,
  fetch,
}) => {
  const personaContext = useContext(PersonasContext);
  const { ocultarMensaje, mensaje } = personaContext;

  const [inicio, setInicio] = useState(true);
  const [loadingLocal, setLoadingLocal] = useState(null);
  const [deshabilitado, setDeshabilitado] = useState(false);

  const [setAlerta, MostrarAlerta] = useAlerta(null);

  const [InsertarLog] = useLog(null);

  const [DatosForm, LeerForm] = useState({
    titulo: "",
    condicion: "",
    id_persona: idpersona,
  });
  const { titulo, condicion } = DatosForm;

  useEffect(() => {
    const alertar = () => {
      if (loadingLocal) {
        setAlerta(null);
      }
      if (inicio) {
        // eslint-disable-next-line
        ocultarMensaje();
        setInicio(null);
        setAlerta(null);
      } else {
        if (mensaje.msg && !loadingLocal) {
          setAlerta({ msg: mensaje.msg, class: mensaje.class });
        }
      }
    };

    alertar();
  }, [mensaje, inicio]);

  useEffect(() => {
    if (Seleccionado) {
      LeerForm(LeerForm);
    }
  }, [Seleccionado]);

  const Insertar = async () => {
    console.log(DatosForm);
    
    if (titulo.trim() === "" || condicion.trim() === "") {
      setAlerta({
        msg: "Condicion y Titulo es obligatorio",
        class: "danger",
      });
      return;
    }

    setAlerta(null);

    try {
      setLoadingLocal(true);

      let formData = new FormData();
      formData.append("persona_id", idpersona);
      formData.append("titulo", titulo);
      formData.append("condicion", condicion);
      formData.append("autor_id", usuario.id);

      const response = await ApiRestPost({
        url: "/condiciones",
        setLoading: () => {},
        form: formData,
      });

      InsertarLog("crear", "Creacion de condicion");
      setLoadingLocal(null);
      setAlerta({ msg: "Se Creo con Exito", class: "success" });
      setDeshabilitado(true);
      fetch();
    } catch (e) {
      setLoadingLocal(null);
      setAlerta({ msg: e.response.data.messages.msg, class: "danger" });
    }
  };

  const Actualizar = async () => {
    setAlerta(null);

    try {
      let formData = new FormData();
      if (titulo.trim() != "") formData.append("titulo", titulo);
      if (condicion.trim() != "") formData.append("condicion", condicion);

      setLoadingLocal(true);

      const response = await ApiRestPost({
        url: "/condiciones/" + Seleccionado.id,
        setLoading: () => {},
        form: formData,
      });

      InsertarLog("editar", "Edicion de Condicion");
      setLoadingLocal(null);
      setAlerta({ msg: "Se Actualizo con Exito", class: "success" });
      setDeshabilitado(true);
      fetch();
    } catch (e) {
      setLoadingLocal(null);
      setAlerta({ msg: e.response.data.messages.msg, class: "danger" });
    }
  };

  const onSubmit = (e) => {
    e.preventDefault();

    if (!Seleccionado) {
      Insertar();
    } else {
      Actualizar();
    }
  };

  const onChange = (e) => {
    LeerForm({
      ...DatosForm,
      [e.target.name]: e.target.value,
    });
  };


  return (
    <div className="center-block">
      <h5 className="text-center">
        {Seleccionado ? "Editar " : "Agregar "} Condición
      </h5>
      <form onSubmit={onSubmit} className="border p-3 form">
        <Input
          key={"titulo"}
          sets={{
            label: "Titulo *",
            type: "text",
            name: "titulo",
            placeholder: "Ingrese Titulo ",
            valor:
              titulo == "" ? Seleccionado?.titulo : titulo,
          }}
          onChange={onChange}
        />

        <Input
          key={"condicion"}
          sets={{
            label: "Condición *",
            type: "text",
            name: "condicion",
            placeholder: "Ingrese Condición ",
            valor:
              condicion == ""
                ? Seleccionado?.condicion
                : condicion,
          }}
          onChange={onChange}
        />

        {loadingLocal ? <Spinner /> : <MostrarAlerta />}

        <div className="col-sm">
          <BotonSubmit label="Guardar" />
        </div>
      </form>
    </div>
  );
};
export default FormCondicion;
