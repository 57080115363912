import React, { useEffect, useState } from "react";
import { Link, useHistory } from "react-router-dom";
import clienteAxios from "../../config/axios";
import styled from "@emotion/styled";
import ItemHeader from "./ItemHeader";
import DropdownMenu from "../shared/DropdownMenu/DropdownMenu";

const DivItemHeader = styled.div`
  color: white !important;
`;

const Header = ({ usuario, cerrarSesion }) => {
  const history = useHistory();

  const colorRol = () => {
    let color;

    switch (usuario.rol) {
      case "Vendedor":
        color = "f68e1e";
        break;
      case "Admin":
        color = "414141";
        break;
      case "Cajero":
        color = "ac49b5";
        break;
      case "Analista":
        color = "6361bc";
        break;
      case "Precalificador":
        color = "6361bc";
        break;
      default:
        break;
    }

    return color;
  };

  const ContenedorHeader = styled.nav`
    background-color: #${colorRol()} !important;
    padding: 5px 5px 5px 5px;
  `;

  const [notificaciones, setNotificaciones] = useState(null);
  const [consultar, setConsultar] = useState(null);

  useEffect(() => {
    const notificar = async () => {
      try {
        if (!notificaciones) {
          const result = await clienteAxios.get("/Notificaciones/getAll", {
            params: { id: usuario.rol },
          });
          setNotificaciones(result.data.observaciones);
        }
      } catch (e) {}
    };
    notificar();
  }, [consultar]);

  return (
    <>
      <ContenedorHeader
        className="navbar navbar-expand-md navbar-light bg-light"
        style={{
          backgroundColor:
            window.location.pathname === "/" ? "white" : "#ecf0f5",
        }}
      >
        <Link aria-label="Home" className="navbar-brand" to="/">
          <DivItemHeader>
            <i className="fa fa-credit-card fa-2x"></i>
          </DivItemHeader>
        </Link>
        {usuario ? (
          <>
            <button
              type="button"
              className="navbar-toggler"
              data-toggle="collapse"
              data-target="#navbarCollapse"
              onClick={() => {
                document
                  .getElementById("navbarCollapse")
                  .classList.toggle("show");
              }}
            >
              <span className="navbar-toggler-icon"></span>
            </button>
            <div
              className="collapse navbar-collapse justify-content-between"
              id="navbarCollapse"
            >
              <div className="navbar-nav">
                <ItemHeader label="Mi perfil" to="perfil" />
                <ItemHeader label="Escritorio" to="dashboard" />
                <ItemHeader label="Clientes" to="clientes" />

                {usuario.rol == "Admin" || usuario.rol == "Cajero" ? (
                  <ItemHeader label="Pagos" to="pagos" />
                ) : (
                  ""
                )}

                {usuario.rol == "Admin" || usuario.rol == "Cajero" ? (
                  <ItemHeader label="Reportes" to="reportes" />
                ) : (
                  ""
                )}

                {usuario.rol == "Vendedor" ? (
                  <ItemHeader
                    label="Calculadora Prestamo"
                    to="prestamos/calculadora"
                  />
                ) : (
                  ""
                )}

                {usuario.rol == "Vendedor" ? (
                  <ItemHeader label="Creditos" to="creditos" />
                ) : (
                  ""
                )}

                {usuario.rol === "Admin" ? (
                  <>
                    <ItemHeader
                      label="Calculadora Prestamo"
                      to="prestamos/calculadora"
                    />
                    <ItemHeader label="Creditos" to="creditos" />
                    <ItemHeader label="Tasas de Interes" to="tasas" />
                    <ItemHeader label="Concesiones" to="concesiones" />
                    <ItemHeader label="Usuarios" to="usuarios" />
                  </>
                ) : null}
              </div>

              <div className="navbar-nav">
                <DropdownMenu
                  notificaciones={notificaciones}
                  setConsultar={setConsultar}
                />

                <Link
                  aria-label="Usuarios"
                  to="/"
                  className="nav-item nav-link"
                >
                  <DivItemHeader>Hola {usuario.nombre}! </DivItemHeader>
                </Link>
                <Link
                  aria-label="Cerrar Sesion"
                  to=""
                  onClick={() => {
                    cerrarSesion();
                    history.push("/");
                  }}
                  className="nav-item nav-link"
                >
                  <DivItemHeader>Cerrar Sesion</DivItemHeader>
                </Link>
              </div>
            </div>
          </>
        ) : null}
      </ContenedorHeader>
    </>
  );
};

export default Header;
