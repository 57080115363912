import { useContext, useEffect, useState } from "react";
import GestionProcesos from "./GestionProcesos";
import apiAxios from "../../../../config/ApiAxios";
import { AuthContext, Spinner } from "../../../shared";
import { ApiRestPost } from "../../../../Helpers/ApiRestPost";
import Swal from "sweetalert2";

const GestionProcesosController = () => {
  const authContext = useContext(AuthContext);
  const { usuario } = authContext;
  const [data, setData] = useState(null);
  const [sort, setSort] = useState("");
  const [loading, setLoading] = useState(false);
  const [estados, setEstados] = useState(null);
  const [params, setParams] = useState({
    page: 1,
    per_page: 15,
  });

  const onClick = async (e, value) => {
    switch (e.target.name) {
      case "sort":
        {
          setData(null);
          setSort(value.sort);
          let reparams = {
            ...params,
            sort: value.sort,
          };
          setParams({
            ...reparams,
          });
          fetch(reparams);
        }
        break;
      case "anterior":
        {
          if (params.page !== 1) {
            setLoading(true);
            setParams({
              ...params,
              page: params.page - 1,
            });

            let reparams = {
              ...params,
              page: params.page - 1,
            };

            fetch(reparams);
          }
        }
        break;
      case "siguiente":
        {
          if (params.page < data.last_page) {
            setLoading(true);
            setParams({
              ...params,
              page: params.page + 1,
            });

            let reparams = {
              ...params,
              page: params.page + 1,
            };

            fetch(reparams);
          }
        }
        break;
      case "link":
        {
          setLoading(true);
          setParams({
            ...params,
            page: value,
          });

          let reparams = {
            ...params,
            page: value,
          };

          fetch(reparams);
        }
        break;

      case "eliminar-proceso":
        {
          //LLave de administrador
          Swal.fire({
            title: "Eliminar proceso",
            html: "<p><strong>Escribe el motivo para dar de baja el proceso</strong></p>",
            input: "textarea",
            inputAttributes: {
              autocapitalize: "off",
            },
            showCancelButton: true,
            cancelButtonText: "Cancelar",
            confirmButtonText: "Eliminar",
            showLoaderOnConfirm: true,
            preConfirm: async (result) => {
              let formData = new FormData();
              formData.append("_method", "PUT");
              formData.append("estado", "inactivo");
              formData.append("motivo_baja", result);
              formData.append("usuario_baja_id", usuario.id);
              const response = await ApiRestPost({
                url: "/procesos/" + value.id,
                setLoading: setLoading,
                form: formData,
              });
            },
            allowOutsideClick: () => !Swal.isLoading(),
          }).then((result) => {
            console.log("ok");
          });

          /*
          let formData = new FormData();
          formData.append("_method", "PUT");
          formData.append("motivo_baja", "PUT");
          const response = await ApiRestPost({
            url: "/procesos/" + value.id,
            setLoading: setLoading,
            form: formData,
          });
          */
        }
        break;
      default:
        break;
    }
  };

  const onChange = (e, value) => {
    switch (e.target.name) {
      case "search":
        {
          let reparams = {
            ...params,
            search: e.target.value,
          };
          setLoading(true);
          setParams({
            ...reparams,
          });
          fetch(reparams);
        }
        break;

      default:
        break;
    }
  };

  const fetch = async (params) => {
    const resultData = await apiAxios.get("/procesos", { params });
    const resultEstados = await apiAxios.get("/procesos/estados");

    setData(resultData.data);
    setEstados(resultEstados.data);
  };

  useEffect(() => {
    if (!data) {
      fetch();
    }
  }, []);


  return (
    <GestionProcesos
      onClick={onClick}
      onChange={onChange}
      data={data}
      params={params}
      sort={sort}
      setSort={setSort}
    />
  );
};

export default GestionProcesosController;
